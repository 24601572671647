const Pros = () => {
  return (
    <>
      <div className="relative">
        <div className="mx-auto max-w-7xl py-20 md:py24 md:flex-row flex flex-col items-center justify-between">
          <div className="mx-auto w-full max-w-md md:mx-auto md:max-w-none relative flex-shrink-0 md:w-1/2">
            <img className="rounded" src="https://treact.owaiskhan.me/static/media/prototype-illustration.21bc4b3f612a2f257c3d361067582485.svg" alt="" />
          </div>

          <div className="mx-auto w-full max-w-md md:mx-0 md:max-w-none mt-16 md:w-1/2 md:mt-0 md:order-first md:mr-12 lg:mr-16">
            <div>
              <h5 className="text-indigo-500 md:text-left text-center font-bold">Rozwój</h5>
              <h2 className="tracking-wide font-black text-4xl text-center sm:text-5xl mt-4 leading-5 md:text-left lg:text-5xl">Nieustanny rozwój dla <span className="text-indigo-500">Twojej</span> wygody</h2>
              <p className="text-slate-500 leading-relaxed font-medium text-sm text-center mt-8 md:text-left md:text-base lg:text-lg">
              Wierzymy w siłę współpracy z naszymi użytkownikami, dlatego jesteśmy otwarci na Wasze pomysły! Chcemy, aby PromoBoss spełniał Twoje oczekiwania w największym stopniu. Właśnie dlatego nasz zespół stale pracuje nad wzbogaceniem funkcji i usprawnieniem serwisu, ale potrzebujemy również Twojej opinii!
              </p>
              <div className="flex flex-col max-w-xs mx-auto md:mx-0 lg:max-w-none lg:flex-row">
                <div className="flex flex-col mt-10 items-center md:mr-8 md:items-start lg:mt-8">
                  <div className="flex items-center">
                    <div className="rounded-full border-0 bg-green-500 text-green-900 text-center p-2 flex-shrink-0 inline-block mx-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-5 h-5 feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    </div>
                    <div className="ml-3 text-xl font-bold">Twoje pomysły mają znaczenie</div>
                  </div>
                  <p className="md:text-left mt-4 text-center leading-relaxed text-slate-500">
                  Cenimy sobie Twój głos i chętnie wysłuchamy Twoich sugestii. Jesteśmy otwarci na Wasze propozycje, aby rozwijać PromoBoss zgodnie z Waszymi potrzebami.
                  </p>
                </div>
                <div className="mt-10 flex flex-col items-center md:mr-8 md:items-start lg:mt-8 mr-0">
                <div className="flex items-center">
                  <div className="rounded-full border-0 bg-green-500 text-green-900 text-center p-2 flex-shrink-0 inline-block mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-5 h-5 feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  </div>
                  <div className="ml-3 text-xl font-bold">Rosnąca baza obsługiwanych sklepów</div>
                  </div>
                  <p className="md:text-left mt-4 text-center leading-relaxed text-slate-500">
                  Dążymy do zapewnienia Ci jak największego wyboru promocji i okazji w sklepach internetowych i serwisach aukcyjnych. Dlatego też, stale powiększamy bazę obsługiwanych sklepów internetowych
                  </p>
                </div>
              </div>
              <a href="" className="bg-indigo-500 text-white rounded-full md:mx-0 inline-block text-sm mt-12 py-3 px-8 font-bold">Czytaj więcej</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Pros;