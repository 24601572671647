import axios from "axios";
import { useEffect, useState } from "react";
import { WorkersType } from "../types";

const Admin = () => {
    const [workers, setWorkers] = useState<WorkersType>()
    const [loadingWorkers, setLoadingWorkers] = useState(true)
    const updateWorkers = () => {
        axios.get(`/base/workers/`).then(
            (res) => {
                if (res.data) {
                    setWorkers(res.data);
                    console.log(res.data)
                    setLoadingWorkers(false);
                } 
            }
        )
    }
    useEffect(() => updateWorkers(), []);

    const handleStatusChange = (ev: any) => {
        if (!ev.target.name) {
            return;
        }
        console.log(`${ev.target.name}:${String(Number(ev.target.checked))}`)
        let data = new FormData();
        data.append('update', `${ev.target.name}:${String(Number(ev.target.checked))}`);
        
        axios({
            method: 'post',
            url: '/base/workers/',
            data: data
        }
        )
        updateWorkers();
    }

    return (
        <>
            <div className="my-4 py-4 bg-slate-500 rounded-lg px-3">
                <div>
                    <div className="flex flex-row">
                        <p>Deals Bot:</p>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={ loadingWorkers } checked={ Boolean(Number(workers?.deals_bot)) } className="sr-only peer" onChange={ handleStatusChange } name="deals_bot" />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>
                        </label>
                    </div>
                    <br />
                    <p>Auctions Bot:</p>
                    <div>
                        {workers?.auctions_bot}
                        {/* TODO: switch */}
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Admin;