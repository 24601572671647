import { useEffect, useState } from "react";
import Items from "./Items";
import { stepProps } from "./types";

const BlockedPhrases = ({ register, setValue }: stepProps) => {
  const [blackPhrases, setBlackPhrases] = useState<string[]>( [] );
  const [blackPhrase, setBlackPhrase] = useState<string>("");
  const addItem = (b: any) => {
    b.preventDefault();
    if (!blackPhrase || blackPhrases.includes(blackPhrase)) {
      return;
    }
    setBlackPhrases([...blackPhrases, ...[...new Set(blackPhrase.split(",").map(el => el.trim().replace('"', '')).filter(el => el !== ""))] ])
    setBlackPhrase("");
    return;
  }

  const handleItemDelete = (item: string) => {
    let s = blackPhrases;
    s.splice(blackPhrases.indexOf(item), 1);
    setBlackPhrases([ ...s ])
  }

  useEffect(() => {
    setValue && setValue('black_phrases', blackPhrases)
  }, [blackPhrases])

  return (
    <>
    <p className="text-slate-600 text-lg text-center mt-6">Zablokowane frazy</p>
    <div className="flex lg:flex-row flex-col items-center">
      <div className="flex flex-col items-center lg:w-9/12">
        <div className="flex flex-row items-center justify-center space-x-2 mt-5 w-11/12">
          <input
            type="text"
            autoComplete="off"
            placeholder="Dodaj frazy zablokowane"
            name="black_phrase" value={blackPhrase}
            id="black_phrase"
            className="w-full rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
            onChange={ (e) => setBlackPhrase(e.target.value) }
          />
          <input
            type="hidden"
            { ...register("black_phrases") }
            value={ blackPhrases }
          />
          <button onClick={ (e) => { addItem(e) } } className="bg-indigo-600 rounded-md py-3 px-4 text-slate-200 font-bold text-lg">+</button>
        </div>

        <div className="text-center flex items-center flex-col">
          <label className="text-sm text-slate-500 tracking-normal w-10/12" htmlFor="name">Zablokowana fraza to zwrot w tytule lub opisie, który wyklucza ogłoszenie jako kwalifikujące się do powiadomienia. Wielkośc liter nie ma znaczenia. Elementy możesz dodawać pojedyńczo lub po przecinku.</label>
        </div>

        <div className="text-center flex items-center w-full flex-col mt-8 mb-3">
          <hr className="text-slate-600 bg-slate-600 w-full" />
          <p className="text-sm text-slate-500 tracking-normal mt-2 w-8/12"><span className="text-indigo-500 font-semibold">Przykład: </span>Szukasz oryginalnego pada do konsoli PlayStation 4. Chcesz uniknąć zamienników oraz uszkodzonych padów. Jako zablokowane frazy możesz wykorzystać np. "cobra", "xbox", "uszkodzony", "vita", "nintendo", "nie działa", "nie dziala", "popsuty", "nieoryginalny", "zamiennik"</p>
        </div>

      </div>
      <div className="wflex flex-row items-center p-4 lg:border-l-slate-500 border-l">
        <p className="text-center">Aktualne zablokowane frazy:</p>
        { blackPhrases && <Items items={ blackPhrases } deleteHandler={ handleItemDelete }/> }
      </div>
    </div>

  </>
  );
}
 
export default BlockedPhrases;