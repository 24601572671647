import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { TelegramUser } from "./telegram_types";


interface props {
	telegramCallback: (user: TelegramUser) => void;
}

declare global {
	interface Window {
		telegramCallback: (user: TelegramUser) => void;
	}
}


function TelegramLoginButton({ telegramCallback }: props) {
	window.telegramCallback = (user: TelegramUser) => {
		telegramCallback(user)
	}
	const instance = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://telegram.org/js/telegram-widget.js?22";
		script.setAttribute("data-telegram-login", "PromoBoss_User_Bot");
		script.setAttribute("data-size", "large");
		script.setAttribute("data-request-access", "write");
		script.setAttribute("data-userpic", "false");
		script.setAttribute("data-onauth", "telegramCallback(user)");
		script.async = true;
		instance.current?.appendChild(script);
	}, []);
  
	return (
	  <div ref={instance}></div>
	);
  }

export default TelegramLoginButton;