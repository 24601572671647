import { useEffect, useState } from "react";
import Items from "./Items";
import { stepProps } from "./types";

const SearchPhrases = ({ register, setValue, setNext }: stepProps) => {
  const [searchPhrases, setSearchPhrases] = useState<string[]>( [] );
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const addItem = (b: any) => {
    b.preventDefault();
    if (!searchPhrase || searchPhrases.includes(searchPhrase)) {
      return;
    }
    setSearchPhrases([...searchPhrases, ...[...new Set(searchPhrase.split(",").map(el => el.trim().replace('"', '')).filter(el => el !== ""))] ])
    setSearchPhrase("");
    // formik.setFieldValue("search_phrases", [...searchPhrases, searchPhrase])
    return;
  }

  const handleItemDelete = (item: string) => {
    let s = searchPhrases;
    s.splice(searchPhrases.indexOf(item), 1);
    setSearchPhrases([ ...s ])
  }

  useEffect(() => {
    setValue && setValue('search_phrases', searchPhrases)
    if (searchPhrases && searchPhrases.length > 0) {
      setNext(true);
    } else {
      setNext(false);
    }
  }, [searchPhrases])

  return (
    <>
    <div className="flex lg:flex-row flex-col items-center">
      <div className="flex flex-col items-center lg:w-9/12">
        <div className="flex flex-row items-center justify-center space-x-2 mt-5 w-11/12">
          <input
            type="text"
            autoComplete="off"
            placeholder="Dodaj frazy wyszukiwania"
            name="search_phrase" value={searchPhrase}
            id="search_phrase"
            className="w-full rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
            onChange={ (e) => setSearchPhrase(e.target.value) }
          />
          <input
            type="hidden"
            { ...register("search_phrases") }
            value={ searchPhrases }
          />
          <button onClick={ (e) => { addItem(e) } } className="bg-indigo-600 rounded-md py-3 px-4 text-slate-200 font-bold text-lg">+</button>
        </div>

        <div className="text-center flex items-center flex-col">
          <label className="text-sm text-slate-500 tracking-normal w-10/12" htmlFor="name">Fraza wyszkiwania to nic innego, jak to, co wpisujesz w serwisie ogłoszeniowym w wyszukiwarce. Wielkość liter nie ma znaczenia. Elementy możesz dodawać pojedyńczo lub po przecinku.</label>
        </div>

        <div className="text-center flex items-center w-full flex-col mt-8 mb-3">
          <hr className="text-slate-600 bg-slate-600 w-full" />
          <p className="text-sm text-slate-500 tracking-normal mt-2 w-8/12"><span className="text-indigo-500 font-semibold">Przykład: </span>Szukasz konsoli PlayStation 3. Jako frazę wyszukiwania dodajesz "playstation 3"</p>
        </div>

      </div>
      <div className="wflex flex-row items-center p-4 lg:border-l-slate-500 border-l">
        <p className="text-center">Aktualne frazy wyszukiwania:</p>
        { searchPhrases && <Items items={ searchPhrases } deleteHandler={ handleItemDelete }/> }
      </div>
    </div>

  </>
  );
}
 
export default SearchPhrases;