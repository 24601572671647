import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosService from "../utils/axios";


interface Photo {
  id: number
  filename: string
  rotation: number
  width: number
  height: number
  link: string
}

interface OlxOffer {
  title: string
  photos: Photo[]
  breadcrumbs: string[]
}

interface OlxMetadata {
  total_elements: number
}


interface OlxUserInfo {
  id: number
  email: string
  status: string
}


const OffersWrapper = () => {
  const [olxConnected, setOlxConnected] = useState(false);
  const [olxUserInfo, setOlxUserInfo] = useState<OlxUserInfo>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [olxOffers, setOlxOffers] = useState<OlxOffer[]>([])
  const [olxMetadata, setOlxMetadata] = useState<OlxMetadata>({ total_elements: 0 })
  const [limit, setLimit] = useState<number>(10)

  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/olx/my-adverts`).then((res) => {
      setOlxOffers(res.data.offers)
      setOlxMetadata(res.data.metadata)
    }).catch(() => {

    })
  }, [])


  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/olx/connect/`).then((res) => {
      setOlxConnected(true);
      setOlxUserInfo(res.data)
    }).catch(() => {
      setOlxConnected(false);
    })
  }, [])

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      axiosService.post(`${process.env.REACT_APP_API_URL}/olx/connect/`, { code: code }).then(() => {
        setSearchParams(undefined)
      })
    }
  })

  return (
    <div className="container flex mx-auto space-x-3">
      <div className="w-3/12 bg-white rounded-md p-4">
        <div className="text-center">
          <p className="font-medium">Połączone konta:</p>
          <div className="my-3">
            <a href={!olxConnected ? `https://www.olx.pl/oauth/authorize/?client_id=${process.env.REACT_APP_OLX_CLIENT_ID}&response_type=code&scope=read+write+v2` : "#!"}
              className={"py-2 px-6 rounded-2xl " + (olxConnected ? "bg-green-500" : "bg-red-600")}
              title={"Kliknij aby " + (olxConnected ? "rozłączyć" : "połączyć")}>
              OLX -
              <small className="font-medium">{olxUserInfo?.email}</small>
            </a>
          </div>

        </div>

        <hr className="my-2" />
        <div>
          <div className="text-center border relative">
            <button className="absolute right-0 top-0">⟳</button>
            <h5 className="font-semibold">OLX</h5>
            <p>Ostatnia synchronizacja:</p>
            <small>01-01-1970 00:00</small>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md p-4 w-full">


        <div>
          <div className="flex justify-between">
            <p className="font-bold">Aktywne ogłoszenia:</p>
          </div>

          <div className="offers-list">
            {olxOffers.map((olxOffer) => (
              <div className="offer-row flex bg-slate-100 px-2 my-3 rounded-lg hover:bg-slate-200">

                <div className="h-full my-auto">
                  <input type="checkbox" />
                </div>{/** checkbox here */}
                <div className="ps-3 pe-6 py-6 w-full">
                  <div className="flex justify-start">
                    <div className="me-4" style={{ width: "216px" }}>
                      <img style={{ minWidth: "216px", height: "152px", objectFit: "cover" }} src={olxOffer.photos[0].link.replace("{width}", "750").replace("{height}", "525")} />
                    </div>
                    <div className="flex flex-col justify-between">
                      <div>
                        <div>
                          <a href="#!" className="hover:underline cursor-pointer">
                            <h3 className="text-lg font-bold text-ellipsis">{olxOffer.title}</h3>
                          </a>
                        </div>
                        <div>
                          <div className="text-sm font-medium">{olxOffer.breadcrumbs.join(" - ")}</div>
                        </div>
                      </div>

                      <div className="flex space-x-3">
                        <div className="bg-green-100 px-2 rounded-lg">olx</div>
                        <div className="bg-red-100 px-2 rounded-lg">vinted</div>
                        <div className="bg-red-100 px-2 rounded-lg">allegroLokalnie</div>
                      </div>

                    </div>
                  </div>
                  <div className="flex justify-end mt-1 space-x-2">
                    <button className="py-1.5 px-2 border border-slate-600 rounded-md bg-slate-300 text-slate-800 font-medium hover:bg-slate-400 hover:text-slate-100 cursor-pointer">Edytuj</button>
                    <button className="py-1.5 px-2 border border-slate-600 rounded-md bg-slate-300 text-slate-800 font-medium hover:bg-slate-400 hover:text-slate-100 cursor-pointer">Zakończ</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <div className="text-center">
          total pages: {Math.ceil(olxMetadata.total_elements / limit)}
        </div>
      </div>
    </div>
  );
}

export default OffersWrapper;