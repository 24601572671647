const First = () => {
  const features = [
    {
      "title": "Zapraszamy na PromoBoss.pl!",
      "description": "Znajdź najlepsze okazje w sieci dzięki PromoBoss - Twojemu wiernemu asystentowi zakupowemu!",
      "img": "https://treact.owaiskhan.me/static/media/shield-icon.daefe14b320b14fbd9cbd18908ac93ec.svg"
    },
    {
      "title": "Potrzebujesz pomocy?",
      "description": "Nasz zespół odpowie na Twoje pytania i rozwiąże problemy w ekspresowym tempie.",
      "img": "https://treact.owaiskhan.me/static/media/support-icon.f9253ffa8cb6ffde5bbaa05eb5136375.svg"
    },
    {
      "title": "Prosta Konfiguracja",
      "description": "Dostosuj funkcjonalność serwisu do swoich potrzeb i oczekiwań. Decydujesz, jak chcesz oszczędzać!",
      "img": "https://treact.owaiskhan.me/static/media/customize-icon.367468c90fd796009b97fbfba67b2c6a.svg"
    },
    {
      "title": "Profesjonalizm na każdym kroku",
      "description": "Zaufaj naszemu doświadczeniu - dbamy, aby PromoBoss działał jak w szwajcarskim zegarku.",
      "img": "https://treact.owaiskhan.me/static/media/reliable-icon.1367510a8f0a1bec76dc425d25f92f43.svg"
    },
    {
      "title": "Nie przegap żadnej okazji!",
      "description": "Dzięki PromoBossowi jako pierwszy dowiesz się o nowych promocjach - już w mniej niż minutę!",
      "img": "https://treact.owaiskhan.me/static/media/fast-icon.dbb971a73d4805d2fc3bcdacdb55beba.svg"
    },
    {
      "title": "Ruszamy w akcję!",
      "description": "Dołącz do PromoBoss.pl i ciesz się oszczędnościami na zakupach online!",
      "img": "https://treact.owaiskhan.me/static/media/simple-icon.673b7e1750b2a4ef32907fc164828d00.svg"
    },
  ]

  return (
    <>
      <div className="relative">
      <div className="mx-auto flex flex-col flex-wrap max-w-5xl items-center py-20 md:flex-row md:items-stretch md:justify-center md:py-24">
        <h2 className="w-full sm:text-5xl text-center text-4xl font-black tracking-wide">PromoBoss</h2>
        <p className="w-full text-center mt-4 max-w-xl text-sm font-medium leading-relaxed text-slate-500 md:text-base lg:text-lg">
Oszczędzaj czas i pieniądze z PromoBoss!
Najlepsze oferty, konfiguracja pod Twoje potrzeby. Profesjonalne wsparcie, szybkie powiadomienia. Wykorzystaj okazje!</p>
        <div className="mt-10 w-full"></div>

        {
          features.map((feature) => (
            <div className="max-w-sm md:w-1/2 lg:w-1/3">
            <div className="flex h-full mx-4 flex-col text-center py-8 px-2 items-center sm:flex-row sm:items-start sm:text-left">
              <span className="icon flex-shrink-0 rounded-full border p-5">
                <img src={ feature.img } alt="" className="max-w-full h-6 w-6" />
              </span>
              <span className="sm:ml-4 sm:mt-2 mt-4">
                <span className="title mt-4 text-2xl font-bold leading-4 tracking-wide">{ feature.title }</span>
                <p className="description mt-1 font-medium leading-8 sm:mt-4 text-slate-500">{ feature.description }</p>
              </span>
            </div>
          </div>
          ))
        }

      </div>
    </div>
    </>

  );
}
 
export default First;