import { useState } from "react";
import { stepProps } from "./types";

const Price = ({ register, setNext }: stepProps) => {
  const [minPrice, setMinPrice] = useState<number>()
  const [maxPrice, setMaxPrice] = useState<number>()
  const [minError, setMinError] = useState<string>()
  const [maxError, setMaxError] = useState<string>()

  const handleChange = (e: any, type: string) => {
    const currentValue = e.target.value;
    let setter: React.Dispatch<React.SetStateAction<string | undefined>>;
    setter = setMinError;
    if (type === "max") {
      setter = setMaxError;
      setMaxPrice(currentValue)
    } else {
      setMinPrice(currentValue)
    }

    if (currentValue === "" || !currentValue) {
      setNext(false);
      return;
    }

    // if (!/^\d+$/.test(currentValue)) {
    //   setter("Wartość musi być liczbą!")
    //   setNext(false);
    //   return;
    // } else {
    //   setter("")
    // }
    
    if (currentValue < 0) {
      setter("Wartość nie może być mniejsza od zera")
      setNext(false);
      return;
    } else {
      setter("")
    }

    // finalValidation();
    setNext(true);
  }

  // const finalValidation = () => {
  //   if (minPrice && maxPrice && minPrice > 0 && maxPrice > minPrice) {
  //     setNext(true);
  //   } else {
  //     if (maxPrice && minPrice && maxPrice <= minPrice) {
  //       setMaxError("Maksymalna cena musi być wyższa od minimalnej!");
  //       setNext(false);
  //       return;
  //     }
  //     setMaxError("");
  //     setNext(false)
  //   }
  // }


  return (
    <div className="mt-2">
      <p className="text-center text-slate-600 font-medium text-lg">Określ minimalną oraz maksymalną cenę</p>
      <div className="flex lg:flex-row items-center flex-col justify-center space-y-3 lg:space-y-0 lg:space-x-5 w-full mt-4 mb-8">
        <div>
          <input
            type="number"
            autoComplete="off"
            placeholder="Cena minimalna"
            { ...register("min_price", {required: true, min: 0, onChange(event) {
              handleChange(event, "min")
            },}) }
            className="rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
          />
          <p className="text-red-500 text-sm font-medium text-center">{ minError }</p>
        </div>

        <div>
          <input
            type="number"
            autoComplete="off"
            placeholder="Cena maksymalna"
            { ...register("max_price", {required: true, onChange(event) {
              handleChange(event, "max")
            },}) }
            className="rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
          />
          <p className="text-red-500 text-sm font-medium text-center">{ maxError }</p>
        </div>

      </div>
    </div>
  );
}
 
export default Price;