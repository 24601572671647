const Second = () => {
  return (
<div className="relative">
<div className="mx-auto flex max-w-7xl justify-between flex-col py-20 md:flex-row md:py-24">
  <div className="h-80 flex-shrink-0 mx-auto w-full max-w-md md:h-auto md:w-5/12 md:mx-0 md:max-w-none">
    <div style={{ backgroundImage: "url(" + "https://treact.owaiskhan.me/static/media/team-illustration-2.76aa0427e037826d4453f8791ee544e3.svg" + ")" }} className="h-full rounded bg-contain bg-center bg-no-repeat" >

    </div>
  </div>
  
  <div className="mx-auto w-full max-w-md mt-16 md:mx-0 md:max-w-none md:mt-0 md:w-7/12 md:-order-last md:mr-12 lg:mr-16">
    <div className="text-center md:text-left lg:py-8">
      <h5 className="text-indigo-500 font-bold text-center md:text-left">Społeczność</h5>
      <h2 className="text-center text-4xl font-black tracking-wide mt-4 leading-5 sm:text-4xl md:text-left lg:text-5xl">
Dołącz do naszej społeczności PromoBoss!
      </h2>
      <p className="text-slate-500 leading-relaxed font-medium text-sm text-center mt-4 md:text-left md:text-base lg:text-lg">
Odkryj nieograniczone możliwości oszczędzania czasu i pieniędzy. Wyjątkowe oferty czekają na Ciebie! Zostań częścią naszego ekscytującego świata zakupowych okazji.
      </p>
      <div className="max-w-sm mt-8 mx-auto md:mx-0">
        <div className="flex flex-col items-start mt-8 md:flex-row">
          <div className="mx-auto inline-block flex-shrink-0 rounded-full border border-indigo-500 text-center p-2 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-briefcase h-5 w-5 text-indigo-500"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
          </div>
          <div className="mt-4 text-center md:mt-0 md:ml-4 md:text-left">
            <div className="text-indigo-500 font-bold text-lg">Szeroki wybór</div>
            <div className="mt-1 text-sm text-slate-600">
            Od korzystnych cen po ekskluzywne wyprzedaże - u nas znajdziesz to, czego szukasz!
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start mt-8 md:flex-row">
          <div className="mx-auto inline-block flex-shrink-0 rounded-full border border-indigo-500 text-center p-2 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-briefcase h-5 w-5 text-indigo-500"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
          </div>
          <div className="mt-4 text-center md:mt-0 md:ml-4 md:text-left">
            <div className="text-indigo-500 font-bold text-lg">Zróżnicowane kategorie</div>
            <div className="mt-1 text-sm text-slate-600">
            Odkryj szeroki wachlarz naszych kategorii, spełnimy Twoje najróżniejsze potrzeby.
            </div>
          </div>
        </div>

      </div>
      <a href=""></a>
    </div>
  </div>
</div>
</div>
  );
}
 
export default Second;
