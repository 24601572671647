const Steps = () => {
  const steps = [
    {
      "number": "01",
      "title": "Zarejestruj się",
      "description": "Rozpocznij swoją przygodę z PromoBoss, rejestrując się już dziś! Odkryj nieograniczone promocje i okazje, dostosowane do Twoich potrzeb."
    },
    {
      "number": "02",
      "title": "Odkryj nasze kategorie",
      "description": "Po rejestracji odkryjesz nasze różnorodne kategorie - od modnych ubrań po sprzęt elektroniczny. Wybór jest ogromny!"
    },
    {
      "number": "03",
      "title": "Bądź na bieżąco",
      "description": "Otrzymuj powiadomienia o wyjątkowych promocjach i oszczędzaj na swoich ulubionych produktach."
    },

  ]

  return ( 
    <div className="relative">
      <div className="mx-auto flex flex-col max-w-7xl items-center justify-between py-20 md:flex-row md:py-24">
        <div className="mx-auto w-full max-w-md md:mx-0 md:max-w-none relative flex-shrink-0 md:w-1/2">
          <img className="align-middle max-w-full h-auto rounded" src="https://treact.owaiskhan.me/static/media/hero-screenshot-2.241aac1fbc66db29d873.png" alt="" />
        </div>

        <div className="mx-auto w-full max-w-md md:mx-0 md:max-w-none mt-16 md:mt-0 md:w-1/2 md:order-last md:ml-12 lg:ml-16">
          <div className="text-center md:text-left lg:py-8">
            <h5 className="font-bold text-indigo-500">
              <span className="font-bold tracking-widest">Rozpocznij Łowczenie Okazji!</span>
            </h5>
            <h2 className="tracking-wide font-black text-4xl text-center sm:text-5xl mt-4 leading-5 md:text-left lg:text-5xl">
              Trzy proste kroki, zeby zacząć <span className="text-indigo-500">oszczędzać</span> już dziś!
            </h2>
            <ul className="mt-12">
              {
                steps.map((step) => (
                  <li className="mt-8 flex flex-col items-center md:flex-row md:items-start">
                    <div className="text-4xl font-semibold leading-4 text-slate-300">{ step.number }</div>
                    <div className="mt-3 md:mt-0 md:ml-6">
                      <h6 className="text-xl font-semibold leading-4">{ step.title }</h6>
                      <p className="text-slate-500 leading-8 font-medium text-sm max-w-xs mt-3">{ step.description }</p>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default Steps;