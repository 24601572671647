type mainProps = {
  alert_date?: string
  offer_date?: string
  title?: string
  price?: number
  description?: string
}

const MainContainer = (props: mainProps) => {
  const alertFormattedDate = `${props.alert_date?.slice(0, 10)} ${props.alert_date?.slice(11, 19)}`
  const offerFormattedDate = `${props.offer_date?.slice(0, 10)} ${props.offer_date?.slice(11, 19)}`

  return (
    <div className="bg-white p-4 mb-2">
      <div className="flex flex-row justify-between w-full">
        <p className="text-xs font-medium tracking-wide text-slate-600">Alert znaleziono { alertFormattedDate }</p>
        { props.offer_date && <p className="text-xs font-medium tracking-wide text-slate-600">Ogłoszenie dodano: { offerFormattedDate }</p> }
      </div>
      <h1 className="mt-5 mb-6 text-4xl leading-7 font-medium">{ props.title }</h1>
      <p className="font-bold text-5xl">{ props.price } zł</p>
      <hr className="h-px text-slate-600 my-5" />
      <div className="text-slate-800">
        { props.description }
      </div>
    </div>
  );
}
 
export default MainContainer;