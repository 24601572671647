import React from "react";
import { Navigate, Route, RouteProps, Outlet } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../store";


interface CustomRouteProps {
    path?: string;
    element?: React.ReactNode;
    root?: boolean;
    referer?: string;
}

const ProtectedRoute = (props: CustomRouteProps) => {
    const { root, path, element } = props;
    const auth = useSelector((state: RootState) => state.auth);

    if (!auth.account) {
        if (root !== undefined) {
            return <div>Not found #TODO: 404</div>
        }
        let nav_url = "/logowanie"
        if (props.referer) {
            nav_url += "?ref=" + props.referer
        }
        console.log(nav_url)
        return <Navigate to={nav_url} replace />;
    } else if (auth.account) {
        if (path === "/logowanie") {
            return <Navigate to={"/"} replace />;
                }
        // @ts-ignore
        if (root && !auth.account.is_staff) {
            return <div>Not found #TODO: 404</div>
        }
        return <Outlet />;
    } else {
        return <div>Not found #TODO: 404</div>
    }
    
}

export default ProtectedRoute;