import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useState } from "react";

const Contact = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)

  const formdata = new FormData();

  const handleMessage = (email: string, name: string, topic: string, message: string) => {
    formdata.append('email', email)
    formdata.append('name', name)
    formdata.append('topic', topic)
    formdata.append('message', message)
    axios
      .post(`/base/contact/`, formdata)
      .then((res) => {
        if (res.status === 200) {
          setIsSuccess(true)
          formik.resetForm()
        }

        setIsSent(true)
      }).catch((err) => {
        setIsSent(true)
      })
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      topic: "",
      message: ""
    },
    onSubmit: (values) => {
      console.log(values)
      handleMessage(values.email, values.name, values.topic, values.message)
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(4, "Podany adres e-mail jest zbyt krótki")
        .max(160, "Podany adres e-mail jest zbyt długi")
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Podaj prawidłowy adres e-mail")
        .required("Adres e-mail jest wymagany"),

      name: Yup.string()
        .max(200, "Podana nazwa jest zbyt długa")
        .required("To pole jest wymagane"),

      topic: Yup.string()
        .max(150, "Temat może się składac maksymalnie ze 150 znaków")
        .required("Temat jest wymagany"),
      message: Yup.string()
        .min(50, "Wiadomość musi mieć minimum 50 znaków")
        .max(2000, "Wiadomość może mieć maksymalnie 2000 znaków")
        .required("Wiadomość jest wymagana")
    })

  });

  return (
    <div className="relative">
      <div className="flex flex-col max-w-7xl mx-auto justify-between py-20 md:flex-row md:py-24">
        <div className="mx-auto w-full max-w-md h-5 flex-shrink-0 md:mx-0 md:max-w-none md:h-auto md:w-5/12">
          <div className="h-full bg-no-repeat bg-center bg-contain" style={{ backgroundImage: "url(" + "https://treact.owaiskhan.me/static/media/email-illustration.84fb82b841c197337a4bc2c1e660d5a0.svg" + ")" }}></div>
        </div>

        <div className="mx-auto w-full max-w-md mt-16 md:mx-0 md:max-w-none md:mt-0 md:w-7/12 md:order-first md:mr-12 lg:mr-16">
          <div className="text-center md:text-left lg:py-8">
            <h5 className="text-indigo-500 text-center font-bold md:text-left">Kontakt</h5>
            <h2 className="text-center text-4xl font-black tracking-wide sm:text-5xl mt-4 leading-5 md:text-left lg:text-5xl">Skontakuj się <span className="text-indigo-500">z nami</span></h2>
            <p className="mt-4 text-center text-sm font-medium leading-relaxed text-slate-500 md:text-left md:text-base lg:text-lg">Jeśli nie znalazłeś odpowiedzi na swoje pytaniew sekcji FAQ, zapraszamy do kontaktu.</p>
            <form className="mx-auto flex flex-col max-w-sm text-sm mt-8 md:mx-0 md:mt-10" onSubmit={formik.handleSubmit}>
              <input
                type="email"
                placeholder="Twój Adres E-mail"
                name="email"
                id="email"
                className="mt-0 border-b-2 p-3 font-medium"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email ? <div className='text-xs text-center text-red-600'>{formik.errors.email} </div> : null}
              <input
                type="text"
                placeholder="Imię i Nazwisko"
                name="name"
                id="name"
                className="mt-6 border-b-2 p-3 font-medium"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name ? <div className='text-xs text-center text-red-600'>{formik.errors.name} </div> : null}
              <input
                type="text"
                placeholder="Temat"
                name="topic"
                id="topic"
                className="mt-6 border-b-2 p-3 font-medium"
                value={formik.values.topic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.topic ? <div className='text-xs text-center text-red-600'>{formik.errors.topic} </div> : null}
              <textarea
                name="message"
                id="message"
                placeholder="Twoja Wiadomość"
                className="h-24 mt-6 border-b-2 p-3 font-medium"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.message ? <div className='text-xs text-center text-red-600'>{formik.errors.message} </div> : null}

              {(isSent && isSuccess) ? <div className='text-xs text-center text-green-600'>Wiadomość została wysłana</div> : null}
              {(isSent && !isSuccess) ? <div className='text-xs text-center text-red-600'>Wysyłanie wiadomości nie powiodło się. Spróbuj ponownie lub popraw błędy</div> : null}
              <button type="submit" className="mt-8 inline-block rounded bg-indigo-500 py-3 px-8 text-white font-bold">Wyślij</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;