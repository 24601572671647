const FAQ = () => {
  const faqs = [
    {
      "id": 1,
      "question": "Jak zarejestrować się na PromoBoss?",
      "answer": 'Wystarczy kliknąć przycisk "Zarejestruj się", podać swoje dane i potwierdzić adres e-mail.'
    },
    {
      "id": 2,
      "question": "Czy korzystanie z PromoBoss jest bezpieczne?",
      "answer": "Tak, dbamy o bezpieczeństwo Twoich danych. Nasza platforma korzysta z zabezpieczeń zgodnych z dzisiejszymi standardami."
    },
    {
      "id": 3,
      "question": "Czy istnieje wersja mobilna aplikacji?",
      "answer": "Trwają prace nad aplikacją mobilną na systemy operacyjne Android"
    },
    {
      "id": 4,
      "question": "Czy PromoBoss jest darmowy?",
      "answer": "Tak, rejestracja i podstawowe funkcje PromoBoss są dostępne bezpłatnie. Oferujemy również płatne plany z dodatkowymi funkcjami."
    },
    {
      "id": 5,
      "question": "Jak bezpośrednio się z Wami skontaktować?",
      "answer": "Możesz skontaktować się z nami poprzez formularz kontaktowy na stronie lub wysłać wiadomość e-mail na nasz adres. Posiadamy również własny serwer discord, znajdziesz go w stopce strony."
    }
  ]

  const toggleAnswer = (element_id: number) => {
    const element = document.getElementById("answer-" + element_id)

    const remove_when_off = ['opacity-0', 'h-0', 'mt-0']
    const remove_when_on = ['opacity-1', 'h-auto', 'mt-4']

    if (element?.classList.contains('opacity-0')) {
      remove_when_off.forEach((el) => {
        element?.classList.remove(el);
      })
      remove_when_on.forEach((el) => {
        element?.classList.add(el)
      })
    } else {
      remove_when_on.forEach((el) => {
        element?.classList.remove(el);
      })
      remove_when_off.forEach((el) => {
        element?.classList.add(el)
      })
    }

  }

  return (
    <>
    <div className="relative">
      <div className="py-20 max-w-7xl mx-auto lg:py-24">
        <div className="flex flex-col items-center">
          <div>
            <h5 className="text-indigo-500 font-bold bn-4 text-center">FAQ</h5>
            <h2 className="w-full sm:text-5xl text-center text-4xl font-black tracking-wide">Masz <span className="text-indigo-500">pytania?</span></h2>
            <p className="text-slate-500 text-center w-full md:text-base lg:text-lg mt-4 max-w-xl text-sm font-medium leading-relaxed">Odpowiadamy na najczęstsze pytania, aby pomóc Ci w pełni korzystać z PromoBoss. Dowiedz się więcej na temat naszej platformy i jej funkcji.</p>
          </div>

          <dl className="relative mt-12 max-w-4xl">
            {faqs.map((faq) => (
              <div id={ "faq-"+ faq.id } className="sm:py-4 sm:px-10 mt-5 cursor-pointer select-none rounded-lg bg-slate-200 py-5 px-8 text-slate-700 hover:bg-slate-300" onClick={ () => { toggleAnswer(faq.id) } }>
                <dt className="flex justify-between items-center">
                  <span className="font-semibold text-lg lg:text-xl">{ faq.question }</span>
                  <span className="ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                  </span>
                </dt>
                <dd id={ "answer-" + faq.id } className="opacity-0 h-0 mt-0 sm:text-base pointer-events-none text-sm leading-relaxed">
                  { faq.answer }
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </>
  );
}
 
export default FAQ;