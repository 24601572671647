const ProductsLoading = () => {
  return (
    <div className="products-list flex flex-col w-full space-y-2">
      {Array(5).fill(1).map(() => (
              <div className="bg-white rounded-xl shadow-md overflow-hidden select-none">
              <div className="md:flex divide-y md:divide-x-2 animate-pulse">
                <div className="md:shrink-0">
                  <div className="h-48 w-full object-cover md:h-full md:w-48 bg-slate-300"></div>
                </div>
                <div className="px-8 py-6 w-full">
                  <div className="flex flex-row justify-between uppercase tracking-wide text-sm">
                    <p className="font-semibold bg-slate-300 text-slate-300 rounded-lg">store name</p>
                    <p className=" bg-slate-300 text-slate-300 rounded-lg">12.12.2022 21:37</p>
                  </div>
                  <div className="block my-1 text-lg leading-tight font-medium rounded-lg bg-slate-300 text-slate-300 w-full h-6"></div>
                  <div className="flex flex-row justify-start space-x-2">
                    <p className="text-slate-300 bg-slate-300 font-semibold text-xl rounded-lg">1000zł</p>
                    <p className="text-slate-300 bg-slate-300 rounded-lg">2000zł</p>
                  </div>
                  <div className="mt-2 flex flex-row md:justify-between w-full">
                    <div className="hidden md:block bg-slate-300 text-slate-300 rounded-lg">author</div>
                    <div className="flex flex-row w-full justify-between md:justify-end md:space-x-3">
                      <button className="rounded-lg border p-1 md:p-2 bg-slate-300 text-slate-300">Zapisz</button>
                      <button className="rounded-lg border p-1 md:p-2 bg-slate-300 text-slate-300">0</button>
                      <button className="bg-slate-300 text-slate-300 p-1 hover:text-indigo-500 font-semibold md:py-2 md:px-4 rounded-lg">Sprawdź w sklepie -&gt;</button>
                    </div>
                  </div>
                </div>
                <div className="md:shrink-0 px-2 py-1">
                  <div className="flex h-full items-center text-center">
                    <div className="flex flex-row justify-between md:block mx-auto space-x-3 md:mx-0 md:space-x-0">
                      <div><i className="fa-solid fa-greater-than -rotate-90"></i></div>
                      <div>10</div>
                      <div><i className="fa-solid fa-greater-than rotate-90"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      ))}

    </div>
)}

export default ProductsLoading;
