import { useEffect, useState } from "react";
import axiosService from "../../../utils/axios";

type breadcrumbsType = {
  offer_id: string
}

type olxBreadcrumb = {
  href: string,
  label: string,
}

type olxBreadcrumbsResponse = {
  data: {
    breadcrumbs: olxBreadcrumb[]
  }
}

const Breadcrumbs = ({ offer_id }: breadcrumbsType) => {
  const [breadcrumbs, setBreadcrumbs] = useState<olxBreadcrumb[]>([]);

  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/olx-breadcrumbs/${offer_id}`).then(
      (res) => {
        const response: olxBreadcrumbsResponse = res.data
        setBreadcrumbs(response.data.breadcrumbs)
      }
    )
  }, [])

  return (
    <div className="mx-2 text-xs font-normal mb-2">
      {
        breadcrumbs?.map((breadcrumb, index) => (
          <>
            <a className="underline" href={ "https://olx.pl" + breadcrumb.href } target="_blank">{ index == 0 ? "OLX" : breadcrumb.label}</a>
            <span>{( index == breadcrumbs.length - 1 ? "" : " > ")}</span>
          </>
        ))
      }
    </div>
  );
}
 
export default Breadcrumbs;