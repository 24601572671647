import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'


function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type ElementType = {
  name: string
  href: string
  content: string
}

type dropdownProps = {
  header: string
  elements: ElementType[]
}

const Dropdown = (props: dropdownProps) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-2">
        <div className="py-1">
          <h1 className='text-lg font-bold px-5 py-3'>{props.header}</h1>
          <hr className='bg-slate-400' />
          <div role="status" className="max-w-md p-1 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-3 dark:border-gray-700">
            <Menu.Item>
              {({ active }) => (
                <div className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center justify-between')}>
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center justify-between pt-4')}>
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center justify-between pt-4')}>
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center justify-between pt-4')}>
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
              )}
            </Menu.Item>
          </div>
        </div>
      </Menu.Items>
    </Transition>
  );
}

export default Dropdown;