import { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { ProductType } from "./types";
import axios from "axios";

const Product = () => {
    const { slug } = useParams();
    const deal_id = slug?.match(/(?!\-)\d{1,}$/)?.[0]
    console.log(deal_id)
    const [deal, setDeal] = useState<ProductType>();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_MAIN_URL}/deals/?id=${deal_id}`).then(
            (res) => { setDeal(res.data) }
        )
    }, [])

    return (
        <>
        <div>{ slug }</div>
        <div>{ deal?.product.name }</div>
        </>
    )
}

export default Product;