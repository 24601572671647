import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

type galleryProps = {
  photos?: string[]
}

const Gallery = (props: galleryProps) => {
  return (
    <div className="bg-white p-4 mb-2">
      <Carousel showArrows={true}>
        {
          props.photos?.map(
            (photo) => 
            <div>
              <img className="w-11/12 mx-auto py-10 max-h-[35rem] object-cover" src={ photo } alt="" />
            </div>
            )
        }

        
      </Carousel>
      
    </div>
  );
}
 
export default Gallery;