type sellerProps = {
  name: string,
  created?: string,
  contactOptions?: string,
  type?: string,
}

const Seller = ({name, created, contactOptions, type}: sellerProps) => {
  return (
    <div className="p-8 bg-white ">
    <div>
      <p>Sprzedaje { name }</p>
      <p>W serwisie olx od { created }</p>
      <p>Możliwości kontaktu: { contactOptions }</p>
      <p>Typ konta: { type }</p>
    </div>
  </div>
  );
}
 
export default Seller;