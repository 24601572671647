import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosService from "../../utils/axios";
import { foundAuctionType } from "../../user/auctions/types";
import OlxAlert from "./OlxAlert";
import Breadcrumbs from "./olx/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios, { AxiosError, AxiosResponse } from "axios";
import { deliveryCostType, olxOfferType } from "./olx/types";
import Gallery from "./Gallery";
import MainContaier from "./MainContainer";
import Location from "./Location";
import Seller from "./Seller";

const Alert = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.account) !== null;
  // const navigate = useNavigate();
  const [urlParams, setUrlParams] = useSearchParams();
  const isPublic = urlParams.get("public")

  if (!isLoggedIn && !isPublic) {
    return <Navigate to="/" replace />;
  }

  const { slug } = useParams();
  const alert_id = slug?.match(/(?!\-)\d{1,}$/)?.[0]

  const [alert, setAlert] = useState<foundAuctionType>();

  useEffect(() => {
    if(isPublic) {
      axios.get(`${process.env.REACT_APP_API_URL}/alert/${alert_id}/?public=${isPublic}`).then(
        (res) => { setAlert(res.data) }
      )
    } else {
      axiosService.get(`${process.env.REACT_APP_API_URL}/alert/${alert_id}/?public=${isPublic}`).then(
        (res) => { setAlert(res.data) }
      )
    }

  }, [])


  const [isActive, setIsActive] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(true)
  const [olxOfferInfo, setOlxOfferInfo] = useState<olxOfferType>();

  useEffect(() => {
    if (!alert?.olx_id) {
      return;
    }
    axiosService.get(`${process.env.REACT_APP_API_URL}/olx-offer-info/${alert.olx_id}/`)
      .then((response: AxiosResponse) => {
        if (!response.data?.data) {
          return;
        }
        setOlxOfferInfo(response.data.data);
        setIsActive(true);
        setIsActiveLoading(false);
        console.log(response.data.data)
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 410) {
          setIsActive(false);
          setIsActiveLoading(false);
        }
      })
  }, [alert?.olx_id])

  const [isDelivery, setIsDelivery] = useState(false);
  const [canAskForDelivery, setCanAskForDelivery] = useState(false)
  const [isSold, setIsSold] = useState(false);
  
  const [contactOptions, setContactOptions] = useState<string[]>( [] )

  const handleContactOptions = () => {
    let contacts = []
    if(olxOfferInfo?.contact.chat) {
      contacts.push('Czat')
    }
    if(olxOfferInfo?.contact.phone) {
      contacts.push('Telefon')
    }
    setContactOptions(contacts)
  }

  const [deliveryCost, setDeliveryCost] = useState<deliveryCostType>();

  const getDeliveryCosts = () => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/olx-delivery-cost/${alert?.olx_id}`)
      .then((response: AxiosResponse) => {
        if(response.data.data) {
          setDeliveryCost(response.data)
        }
      })
      .catch((err: AxiosError) => {
        console.log(err)
      })
  }

  const handleOlxDelivery = () => {
    const delivery_mode = olxOfferInfo?.delivery.rock.mode;
    switch (delivery_mode) {
      case 'NotEligible':
        setIsSold(true);
        return false;
      case 'Ask4Delivery':
        setCanAskForDelivery(true);
        return true;
      case 'BuyWithDelivery':
        setIsDelivery(true);
        getDeliveryCosts();
        return true;
    }
  }

  useEffect(() => {
    if (!isActive) {
      return;
    }
    handleContactOptions()
    if (!handleOlxDelivery()) {
      return;
    }
  }, [isActive])

  


  return (
    <>
    <div className="relative">
      <div className="mx-auto max-w-7xl py-10 lg:py-12">
        { alert?.auction_store == "olx" && <Breadcrumbs offer_id={ alert.olx_id } /> }
        <div className="flex flex-col justify-between xl:flex-row align-top items-start">
          <div className="xl:w-8/12 mx-2">
            { alert?.main_photo ? <Gallery photos={ [alert.main_photo] } /> : <Gallery photos={ [] } /> }
            <MainContaier title={ alert?.title } offer_date={ olxOfferInfo?.created_time } alert_date={ alert?.found_at } price={alert?.price} description={alert?.description} />
          </div>

          <div className="xl:w-4/12 space-y-4">
            <div className="p-8 bg-white">
              { alert?.auction_store === "olx" && <a target="_blank" href={ olxOfferInfo?.url }><img src="https://www.speedtest.pl/wiadomosci/wp-content/uploads/2020/05/OLX-logo.jpg" /></a> }
            </div>
            <div className="p-8 bg-white">
              <p className="text-center text-slate-700 tracking-wide uppercase">Status: { isActiveLoading ? "ładowanie" : isActive ? <span className="text-green-500 font-semibold lowercase">aktywne</span> : <span className="text-red-500 lowercase font-semibold">nieaktywne</span> }</p>
              <hr className="h-px text-slate-500 my-3" />
              <p className="text-center">{ isSold && <span className="text-yellow-600 font-semibold">SPRZEDANO Z PRZESYŁKĄ OLX</span> }{ isDelivery && "DOSTĘPNA WYSYŁKA" }</p>
              { canAskForDelivery &&
                <div className="text-center items-center">
                  <a target="_blank" href={ olxOfferInfo?.url } className="text-center w-full p-3 bg-slate-500 text-white uppercase font-semibold tracking-normal">Zapytaj o przesyłkę OLX</a>
                  <p className="text-xs tracking-wide mt-4">Zostaniesz przekierowany do serwisu <a className="text-indigo-500 font-bold" href="https://olx.pl/">OLX</a> aby móc poprosić o przesyłkę.</p>
                </div>
              }
              <div>
                { isDelivery && deliveryCost?.data.costs.map((cost) => (
                  <div>
                    { cost.type === 'ITEM_PRICE' && cost.price && <p>Koszt przedmiotu: {cost.price?.amount / 100} {cost.price?.currency}</p> }
                    { cost.type === 'DELIVERY_PRICE_FROM' && cost.price && <p>Cena wysyłki (od): {cost.price.amount / 100} {cost.price.currency} [{ cost?.productId }]</p> }
                    { cost.type === 'DELIVERY_PRICE_FROM' && cost.finalPrice && <p>Cena wysyłki (od): {cost.finalPrice.amount / 100} {cost.finalPrice.currency} [{ cost?.productId }]</p> }
                    { cost.type === 'SERVICE_FEE' && cost.price && <p>Opłata serwisowa: { cost.price.amount / 100 } {cost.price.currency }</p> }
                  </div>
                  // (cost.type && <p>{cost.type}</p>)
                  // <p>{ cost.type === 'ITEM_PRICE' && cost.price && ``}</p>
                )) }
              </div>
              { isDelivery && olxOfferInfo?.id && 
                <div className="w-full text-center items-center mt-6">
                  <a target="_blank" href={ "https://delivery.olx.pl/checkout/" + olxOfferInfo.id + "/"} className="text-center w-full p-3 bg-slate-500 text-white uppercase font-semibold tracking-normal">Kup z przesyłką OLX</a>
                  <p className="text-xs tracking-wide mt-4">Aby przejść od razu do strony z przesyłką OLX, musisz być zalogowany na <a className="text-indigo-500 font-bold" href="https://olx.pl/">olx</a></p>
                </div>
              }
            </div>

            { olxOfferInfo?.user && <Seller name={ olxOfferInfo.user.name } created={ olxOfferInfo.user.created } contactOptions={ contactOptions.join(", ") } type={ olxOfferInfo?.business ? "firmowe" : "prywatne" } /> }
            { olxOfferInfo?.location && <Location city={olxOfferInfo.location.city.name} region={olxOfferInfo.location.region.name} lon={olxOfferInfo.map.lon} lat={olxOfferInfo.map.lat} /> }
          </div>


        </div>
      </div>
    </div>
    </>
  );
}
 
export default Alert;