import axios from "axios";
import { useEffect, useState } from "react";
import { MainCategory } from "./types";

const CategoriesList = () => {
    const [mainCategories, setMainCategories] = useState<MainCategory[]>([]);

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_MAIN_URL}/main_categories`).then(
        (res) => {setMainCategories(res.data)}
      )
    }, [])
    

    return (
        <div className="flex flex-row my-2 space-x-2">
            {
                mainCategories.map((category) => (
                    <div className="category-item p-3 rounded-md bg-white text-slate-600 select-none hover:scale-105 hover:transition-transform">{ category.name }</div>
                ))
            }
        </div>
        
    )
}

export default CategoriesList;