import Alerts from "./homepage/alerts/Alerts";

const Homepage = () => {

  return (
    <>
      <Alerts />
    </>
  );
}
 
export default Homepage;