import { UseFormRegister } from "react-hook-form/dist/types";
import { FormValues } from "../types";
import SingleSelectStore from "./singleSelectStore";

type Props = {
  register: UseFormRegister<FormValues>
  setSelectedStores: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectStore = ({ register, setSelectedStores }: Props) => {
  const handleChange = (e: any) => {
    if (e.target.checked) {
      setSelectedStores(selectedStores =>
        selectedStores.includes(e.target.value)
          ? selectedStores
          : [...selectedStores, e.target.value]
      );
    } else {
      // Remove the value from selectedStores when unchecked
      setSelectedStores(selectedStores =>
        selectedStores.filter(store => store !== e.target.value)
      );
    }
  }

  return (
    <div className="flex items-center flex-col">
      <p className="text-center text-slate-600 my-2 font-medium text-lg">Wybierz serwisy, w których mamy prowadzić poszukiwania</p>

      <div className="my-4 grid grid-cols-3 space-x-2">
        <SingleSelectStore storeName="OLX" storeId="olx" register={register} handleChange={handleChange} logo="https://static.olx.pl/static/olxpl/packed/font/2f245edf8d709c906bd6c4b03d1623d647.svg" />
        <SingleSelectStore storeName="Vinted" storeId="vinted" register={register} handleChange={handleChange} logo="https://upload.wikimedia.org/wikipedia/commons/2/29/Vinted_logo.png" />
        <SingleSelectStore storeName="AllegroLokalnie" storeId="al" register={register} handleChange={handleChange} logo="https://lokalnie-prod-assets.storage.googleapis.com/ui/versions/9f22e842/images/favicon-android-chrome-192x192-1202c51ba7dbf5e1e6a2b61c5cbdae04.png" />
      </div>
    </div>
  );
}

export default SelectStore;