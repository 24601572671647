import { useEffect, useState } from "react";
import axiosService from "../../utils/axios";
import { stepProps } from "./types";

type categoryType = {
  id: number,
  count?: number,
  label: string,
  url: string,
}

type Response = {
  breadcrumbs: categoryType[],
  categories: categoryType[],
}

const CategoriesOLX = ({ register, setValue }: stepProps) => {
  const [categories, setCategories] = useState<categoryType[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<categoryType[]>([]);
  const all_categories = {
    id: 0,
    label: "Wszystkie",
    url: ''
  }
  const [selectedCategory, setSelectedCategory] = useState<categoryType>(all_categories);
  const [loading, setLoading] = useState(true);

  const url = `${process.env.REACT_APP_API_URL}/categories_tree/olx/`
  useEffect(() => {
    if (categories.length > 0) {
      return;
    }
    axiosService.get(url)
      .then((res) => {
        const data: Response = res.data;
        setCategories(data.categories)
        setBreadcrumbs(data.breadcrumbs)
        setLoading(false)
      })
  }, [])

  const handleSelect = (e: any, back: boolean = false) => {
    const cat_id = e.target.dataset.category_id;
    setCategories([])
    setLoading(true)
    let params = "";
    if (cat_id !== "0") {
      params = "?p=" + cat_id
    }

    axiosService.get(url + params)
      .then((res) => {
        setCategories(res.data.categories)
        setBreadcrumbs(res.data.breadcrumbs)
        setLoading(false)
      })
    setSelectedCategory(categories.filter((el: categoryType) => { return el.id.toString() === cat_id })[0])
  }

  useEffect(() => {
    setValue && setValue('category_olx', selectedCategory?.id.toString())
    setValue && setValue('olx_category_label', selectedCategory?.label)
  }, [selectedCategory])

  return (
    <div className="mt-2">
      <input type="hidden" {...register("category_olx")} value={selectedCategory?.id} />
      <input type="hidden" {...register("olx_category_label")} value={selectedCategory?.id} />
      <div className="text-center text-slate-600">
        <span>Wybrana kategoria: </span>
        <>
          <a className="underline hover:cursor-pointer font-medium text-sm hover:text-base" onClick={(e) => handleSelect(e)} data-category_id="0" target="_blank">Wszystko</a>
          {/* <span>{( index == breadcrumbs.length - 1 ? "" : " > ")}</span> */}
        </>
        {
          breadcrumbs?.map((breadcrumb, index) => (
            <span key={index}>
              <span> &gt; </span>
              <a className="underline hover:cursor-pointer font-medium text-sm hover:text-base" onClick={(e) => handleSelect(e)} data-category_id={breadcrumb.id} target="_blank">{breadcrumb.label}</a>
            </span>
          ))
        }
      </div>
      <div>
        {/* <div className="bg-slate-50 border hover:bg-slate-300 border-slate-500 rounded-lg px-6 py-3 mx-2 my-1" data-category_id="0" onClick={(e) => handleSelect(e, true)}>Wróć do początku</div> */}
      </div>
      {loading && <p className="text-center">ŁADOWANIE</p>}
      <div className="flex flex-wrap flex-row justify-center">
        {categories !== undefined && categories.map((category: any) => (
          <div className="bg-slate-50 border hover:bg-slate-300 border-slate-500 rounded-lg px-6 py-3 mx-2 my-1" data-category_id={category?.id} onClick={(e) => handleSelect(e)}>{category?.label}</div>

        ))}
      </div>

    </div>
  );
}

export default CategoriesOLX;