import { ProductType } from "./types";


interface ProductsProps {
    products: ProductType[];
}

const ProductsList = ({ products }: ProductsProps) => {
	return (
		<div className="flex flex-col space-y-2 w-full">
			{products.map((obj) => (
				<div className="bg-white rounded-xl shadow-md overflow-hidden">
					<div className="md:flex divide-y md:divide-x-2">
						<div className="md:shrink-0">
							<img className="h-48 w-full object-cover md:h-full md:w-48" src={ obj.product.photo_url ? obj.product.photo_url : "https://via.placeholder.com/600x300.png?text=photo" } alt={ obj.product.name } />
						</div>
						<div className="px-8 py-6 w-full">
							<div className="flex flex-row justify-between uppercase tracking-wide text-sm">
								<p className="text-indigo-500 font-semibold">{ obj.store }</p>
								<p className="text-slate-500">{ obj.date }</p> {/* TODO: format date */}
							</div>
							<a href={ obj.slug } className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{ obj.product.name }</a>
							<div className="flex flex-row justify-start space-x-2">
								<p className="text-indigo-500 font-semibold text-xl">{ obj.product.price }zł</p>
								<p className="text-slate-500 line-through">{ obj.product.last_price }zł</p>
							</div>
							<div className="mt-2 flex flex-row md:justify-between w-full">
								<div className="hidden md:block">author</div>
								<div className="flex flex-row w-full justify-between md:justify-end md:space-x-3">
									<button className="rounded-lg border p-1 md:p-2">Zapisz</button>
									<button className="rounded-lg border p-1 md:p-2">0</button>
									<button className="bg-indigo-500 hover:bg-slate-600 p-1 hover:text-indigo-500 text-slate-200 font-semibold md:py-2 md:px-4 rounded">Sprawdź w sklepie -&gt;</button>
								</div>
							</div>
						</div>
						<div className="md:shrink-0 px-2 py-1">
							<div className="flex h-full items-center text-center">
								<div className="flex flex-row justify-between md:block mx-auto space-x-3 md:mx-0 md:space-x-0">
									<div><i className="fa-solid fa-greater-than -rotate-90"></i></div>
									<div>10</div>
									<div><i className="fa-solid fa-greater-than rotate-90"></i></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
	</div>
	)
}

export default ProductsList;

{/* <a className="product flex flex-row mb-1 lg:max-h-52 bg-elem-dark hover:bg-gray-700" href={ obj.slug }>
<div className="thumbnail text-center h-36 align-middle justify-start items-center px-5 border-white border-1">
    <img className="object-cover w-full object-center h-36" src="https://via.placeholder.com/600x300.png?text=placeholder" />
</div>
<div className="flex flex-col pl-6 items-start text-left justify-between">
    <div className="product-main-info">
        <div className="pb-3 justify-between flex flex-row w-full">
            <div className="product-name text-xl">{ obj.product.name }</div>
            <div>+ votes -</div>
        </div>
        <div className="price-info justify-start flex-row flex space-x-6 items-center">
            <div className="price text-xl text-blue-500 font-bold">{ obj.product.price }zł <s className="text-base">{ obj.product.last_price }</s>                              </div>
            <div className="store">{ obj.store }</div>
            <div className="free-shipping">Free shipping?</div>
        </div>
        <div className="short-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas maximus leo vel libero malesuada, et gravida ex pellentesque. 
            Proin ornare at mi pretium molestie. Phasellus varius venenatis sollicitudin.
        </div>
    </div>

    <div className="info flex flex-row justify-between text-sm w-full items-end">
        <div className="author">Author</div>
        <div className="date">01.01.1970</div>
        <div className="check-offer">
            <button className="bg-blue-500 hover:bg-blue-700 text-white fornt-bold py-2 px-4 rounded">Go to product! &gt;&gt;</button>
        </div>
    </div>
</div>
</a> */}