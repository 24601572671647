import { UseFormRegister } from "react-hook-form/dist/types";
import { FormValues } from "../types";

type Props = {
  register: UseFormRegister<FormValues>,
  handleChange: (e: any) => void,
  storeId: string,
  storeName: string
  logo: string
}

const SingleSelectStore = ({ register, handleChange, storeId, storeName, logo }: Props) => {
  return (
    <div className="flex flex-col items-center text-center">
      <input
        type="checkbox"
        id={storeId}
        value={storeId}
        className="hidden peer"
        {...register("search_on", { required: true, onChange(event) { handleChange(event) } })}
      />
      <label htmlFor={storeId} className="flex flex-col items-center py-7 px-10 w-full rounded-lg border-2 border-slate-400 cursor-pointer peer-checked:border-indigo-600 hover:bg-gray-50">
        <img className="max-h-8" src={logo} alt="" />
        <p className="mt-5 text-slate-600 font-bold text-xs">{storeName}</p>
      </label>
    </div>
  );
}

export default SingleSelectStore;