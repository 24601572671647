import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useEffect } from "react";
import TelegramLoginButton from "./TelegramButton";
import axiosService from "../utils/axios";
import { TelegramUser } from "./telegram_types";
import { AiOutlineWarning } from "react-icons/ai";


interface TgApiResponse {
  connected: boolean
}

const Data = () => {
  const [telegramConnected, setTelegramConnected] = useState(false);

  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/tg-connect/`)
      .then((res) => {
        const data: TgApiResponse = res.data;
        setTelegramConnected(data.connected);
        // setTelegramConnected(true)
      })
  }, [telegramConnected])

  const token = useSelector((state: RootState) => state.auth.token) as string;
  const handleTelegramConnect = (user: TelegramUser) => {
    axiosService.post(`${process.env.REACT_APP_API_URL}/tg-connect/`, user)
      .then((res) => {
        if (res.status === 200) {
          setTelegramConnected(true)
        }
      })
  }

  const handleTelegramDisconnect = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/tg-connect/`, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        if (res.status === 200) {
          setTelegramConnected(false)
        }
      })
  }

  const [verificationCode, setVerificationCode] = useState("1234");

  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/auth/code/`)
      .then((res) => {
        setVerificationCode(res.data.code)
      })
  }, [])

  const [manualTelegram, setManualTelegram] = useState(false);
  const [manualTelegramLoading, setManualTelegramLoading] = useState(false);
  const handleManualTelegramForm = (ev: any) => {
    ev.preventDefault();
    setManualTelegramLoading(true);
    const user_data = ev.target.telegram_response.value;
    ev.target.telegram_response.value = "";
    const data = {
      "manual": true,
      "user_data": user_data
    }
    if (!user_data) return
    axiosService.post(`${process.env.REACT_APP_API_URL}/tg-connect/`, data)
      .then((res) => {
        if (res.status === 200) {
          setTelegramConnected(true)
        }
      })
    setManualTelegramLoading(false);
  }

  return (
    <>
      <div className="relative">
        <div className="mx-auto max-w-7xl py-6 lg:py-6 my-10 px-6 bg-white rounded-3xl">
          <div className="space-y-8">
            <div>
              {telegramConnected ?
                <div className="flex flex-row justify-center items-center space-x-6 py-3">
                  <div className="text-xl">Twoje konto jest już połączone z Telegramem</div>
                  <button onClick={handleTelegramDisconnect} className="bg-blue-400 text-white font-semibold px-5 py-3 rounded-full">Rozłącz</button>
                </div>
                :
                <div className="flex flex-row justify-center items-center space-x-6">
                  <div className="text-xl">Połącz konto z Telegramem:</div>
                  <TelegramLoginButton telegramCallback={handleTelegramConnect} />
                </div>
              }
              {!telegramConnected && <div className="text-center text-sm text-gray-500 flex flex-row justify-center">
                <AiOutlineWarning className="text-yellow-600 text-base" />&nbsp;Nie widzisz przycisku do połączenia z telegramem lub występuje błąd?&nbsp;
                <span className="hover:underline hover:cursor-pointer text-gray-700 select-none" onClick={() => setManualTelegram(!manualTelegram)}>Kliknij tutaj{manualTelegram && ", aby zamknąć instrukcję"}</span>
              </div>}
            </div>

            {!telegramConnected && manualTelegram &&
              <div className="text-center flex flex-col items-center">
                <p className="text-sm">Użytkownicy często zgłaszają problem z widżetem do połączenia z telegramem. Problem leży po stronie widżetu i <a className="text-blue-500 hover:underline hover:cursor-pointer" target="_blank" href="https://i.kym-cdn.com/editorials/icons/original/000/006/715/thereisnothing.jpg">nic nie możemy z tym zrobić</a></p>
                <p>W tym miejscu możesz manualnie dodać swoje konto telegram do PromoBoss</p>
                <p>Podążaj za instrukcją podaną niżej i ciesz się szybkimi alertami PromoBoss na Telegramie</p>
                <ol className="list-decimal w-2/3 m-3 space-y-1">
                  <li>Otwórz ten link: <a href="https://t.me/userinfobot" className="text-blue-500 hover:underline hover:cursor-pointer">https://t.me/userinfobot</a> i kliknij przycisk "SEND MESSAGE"</li>
                  <li>W aplikacji telegram wybierz z listy użytkowników konto 'userinfobot' i wciśnij przycisk START</li>
                  <li>Skopiuj całą otrzymaną wiadomość, wklej ją w poniższym polu i kliknij Zatwierdź.</li>
                </ol>
                <form onSubmit={(ev) => handleManualTelegramForm(ev)} className="flex flex-col justify-between">
                  <textarea name="telegram_response" id="telegram_response" placeholder="@mariusz98pl&#10;Id: 942837136&#10;First: mariusz&#10;Lang: pl" className="text-xs bg-purple-50 rounded-xl p-2" cols={30} rows={5}></textarea>
                  <input type="submit" value="ZATWIERDŹ" className="rounded-lg p-2 mt-2 bg-gray-200 hover:bg-purple-100 hover:cursor-pointer" />
                </form>
              </div>
            }

            <hr className="h-px text-gray-600 bg-gray-600" />

            <div className="flex flex-col justify-center py-10 text-center items-center">
              <h3 className="text-2xl font-medium">Twój kod weryfikacyjny dla bota:</h3>
              <div className="text-4xl p-3 mt-3 bg-green-200 rounded-lg w-min">{verificationCode}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Data;