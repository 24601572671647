import ProductsList from "./ProductsList";
import CategoriesList from "./CategoriesList";

import { useEffect, useState } from "react";
import axios from "axios";
import { ProductType, StoreFilter } from "./types";
import ProductsLoading from "./ProductsLoading";
import AvailableStores from "./sidebars/AvailableStores";

const Deals = () => {
    useEffect(() => {
        document.title = 'PromoBoss - okazje, promocje i błędy cenowe';
    }, []);

    const [products, setProducts] = useState<ProductType[]>( [] );
    const [loadingProducts, setLoadingProducts] = useState(true);

    const fetchProducts = (filter?: StoreFilter) => {
        console.log(filter)
        axios.get(`${process.env.REACT_APP_MAIN_URL}/deals/`, { params: filter }).then(
            (res) => {
                if (res.data) {
                    setProducts(res.data);
                    setLoadingProducts(false);
                } 
            }
        )
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const handleStoreSelection = (storeId: number) => {
        setLoadingProducts(() => {return true});
        setProducts([]);
        fetchProducts({store: storeId})
    }

    return (
        <div className="homepage container mx-auto">
            <div className="categories-list my-2 hidden md:block">
                <CategoriesList />
            </div>
            <div className="flex flex-row justify-between py-2 space-x-3 w-full">
                <div className="w-3/4">{ loadingProducts ? <ProductsLoading /> :<ProductsList products={ products } /> }</div>
                
                
                <div className="space-y-4 xl:block w-1/4">
                <AvailableStores onStoreSelect={ handleStoreSelection } />
                </div>
            </div>
            
        </div>
    )
}

export default Deals;