import { FormValues } from "./types";
import { UseFormGetValues } from "react-hook-form/dist/types";
import React from "react";


type valuesType = {
  name: string,
  label: string,
  backToStep: number,
  value: string,
}

type propsType = {
  getValues: UseFormGetValues<FormValues>,
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const stores_labels = {
  olx: "OLX",
  al: "AllegroLokalnie"
}
type storeLabel = keyof typeof stores_labels

const Summary = ({ getValues, setStep }: propsType) => {
  let values: valuesType[] = []
  if (getValues) {
    values = [
      {
        name: "name",
        label: "Nazwa alertu",
        backToStep: 1,
        value: getValues("name")
      },
      {
        name: "search_on",
        label: "Wybrane serwisy",
        backToStep: 2,
        value: getValues("search_on") && getValues("search_on").map((store: storeLabel) => stores_labels[store] ).join(", ")
      },
      {
        name: "olx_category_label",
        label: "Kategoria OLX",
        backToStep: 3,
        value: getValues("olx_category_label")
      },
      {
        name: "category_al",
        label: "Kategoria AllegroLokalnie",
        backToStep: 4,
        value: getValues("category_al")
      },
      {
        name: "search_phrases",
        label: "Frazy wyszukiwania",
        backToStep: 5,
        value: getValues("search_phrases") && getValues("search_phrases").join(", ")
      },
      {
        name: "identify_phrases",
        label: "Frazy identyfikujące",
        backToStep: 6,
        value: getValues("identify_phrases") && getValues("identify_phrases").join(", ")
      },
      {
        name: "black_phrases",
        label: "Zablokowane frazy",
        backToStep: 7,
        value: getValues("black_phrases") && getValues("black_phrases").join(", ")
      },
      {
        name: "price",
        label: "Ceny",
        backToStep: 8,
        value: getValues("min_price") + " - " + getValues("max_price")
      },
      {
        name: "notifications",
        label: "Metody powiadomień",
        backToStep: 9,
        value: getValues("notifications") && getValues("notifications").join(", ")
      },

    ]
    console.log(getValues());
  }
  return (
    <div className="flex flex-col items-center my-4">
      <p className="text-lg font-medium text-slate-600">Podsumowanie</p>
      <div className="border lg:border-slate-500 lg:rounded-xl lg:w-7/12 p-4 my-2 flex flex-col items-center lg:bg-slate-100 space-y-2">
        {
          values.map((el) => (
            <div className="lg:w-10/12 w-full rounded-lg p-3 text-center bg-slate-100 lg:bg-white relative">
            <i className="hover:cursor-pointer absolute left-0 fa-solid fa-pen ml-2 text-indigo-500 hover:text-indigo-600 hover:font-bold hover:scale-110" onClick={ () => setStep( el.backToStep )}></i>
            <p><span className="text-sm font-medium tracking-wide">{el.label}: </span>{ el.value }</p>
          </div>
          ))
        }
        
      </div>
    </div>
  );
}
 
export default Summary;