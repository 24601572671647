import CategoriesOLX from "../CategoriesOLX";
import { stepProps } from "../types";


const SelectOLXCategory = ({ register, setValue, setNext }: stepProps) => {
  return (
    <div>
      <p className="text-slate-600 font-medium text-center my-4">Wybierz kategorię dla OLX</p>
      <CategoriesOLX register={register} setValue={setValue} setNext={setNext} />
    </div>
  );
}

export default SelectOLXCategory;