import './App.css';
import Header from "./layout/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Deals from './Deals';
import Footer from "./layout/footer/Footer";
import Product from "./Product";
import About from "./About";
import Login from './auth/Login';
import store, { persistor } from "./store";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ProtectedRoute from './routes/ProtectedRoute';
import Register from './auth/Register';
import ProductsFollow from './ProductFollow';
import LandingPage from './landing_page/LandingPage';
import Contact from './Contact';
import Alert from './homepage/alerts/Alert';
import AddAlert from './homepage/add_alerts/AddAlert';
import MyAlerts from './alerts/MyAlerts';
import Dashboard from './dashboard/Dashboard';
import Data from './user/Data';
import Admin from './user/Admin';
import Modal from './modals/FeatureModal';
import OffersWrapper from './offers/OffersWrapper';

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <div className="App overflow-hidden min-h-screen bg-gray-100">
            <Modal />
            <Header />
            <div className="p-8">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/ogloszenia" element={<OffersWrapper />} />
                <Route path="/promocje" element={<Deals />} />
                <Route path="/logowanie" element={<Login />} />
                <Route path="/rejestracja" element={<Register />} />
                <Route path="/moje-konto" element={<ProtectedRoute />}>
                  <Route path="/moje-konto" element={<Dashboard />} />
                </Route>
                <Route path="/ustawienia" element={<ProtectedRoute referer="ustawienia" />}>
                  <Route path='/ustawienia' element={<Data />} />
                </Route>
                <Route path="/admin" element={<ProtectedRoute root={true} />}>
                  <Route path='/admin' element={<Admin />} />
                </Route>

                <Route path="/dodaj-alert" element={<AddAlert />} />
                <Route path="/alert/:slug" element={<Alert />} />
                <Route path="/o-nas" element={<About />} />
                <Route path="/moje-alerty" element={<MyAlerts />} />
                <Route path="/sledzenie-produktow" element={<ProductsFollow />} />

                <Route path="/kontakt" element={<Contact />} />
                <Route path="/:slug" element={<Product />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </PersistGate>
    </Provider>

  );

}

export default App;
