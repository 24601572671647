import { useEffect, useState } from "react";
import { stepProps } from "../types";

interface AiStepProps extends stepProps {
  alertName: string
}

const IntegrationAi = ({ register, setNext, alertName }: AiStepProps) => {
  const [emptyTextarea, setEmptyTextarea] = useState<boolean>(true);
  useEffect(() => {
    setNext(true);
  })

  return (
    <div>
      <p className="text-slate-600 font-medium text-lg text-center my-4">Pomoc Sztucznej Inteligencji (AI)</p>
      <p className="text-slate-600 font-medium text-center mt-3">
        Potrzebujesz dodatkowego filtru, żeby wyselekcjonować najlepsze okazje dla alertu <b>{alertName}</b>?
      </p>
      <p className="text-slate-600 text-center w-8/12 mx-auto mt-1 mb-3">
        Możesz napisać instrukcję (prompt) dla sztucznej inteligencji. Instrukcja ta zostanie zastosowana dla opisu produktu, który spełni wcześniejsze filtry, tj. frazy identyfikacyjne, zakres cen itp.
      </p>
      <div className="text-center">
        <p className="text-slate-600 font-medium text-lg">Zaakceptuj produkt, jeśli z opisu wynika, że...</p>
        <textarea
          {...register("ai_prompt")}
          placeholder='...w zestawie są fizyczne gry'
          rows={6}
          id="al_category_url"
          className=" w-9/12 text-center rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium"
          onChange={(e) => {
            (e.target.value && e.target.value !== "") && (setNext(true), setEmptyTextarea(false));
            (!e.target.value || e.target.value === "") && (setNext(false), setEmptyTextarea(true));
          }}
          disabled={true}
        />
        {emptyTextarea && <>
          <br />
          <small className="text-xs text-slate-700">Podany przykład dotyczy alertu "Nintendo Switch", gdy chcemy być powiadomieni tylko o ofertach z grami</small>
        </>}
      </div>

      <p className="text-red-500 font-medium text-center">Aby skorzystać z AI potrzebujesz konta Premium!</p>

    </div>
  )
}

export default IntegrationAi;