import { useEffect, useState } from "react";
import { fetcher } from "../utils/axios";
import axios from "axios";


type StoreType = {
    id: number,
    name: string,
    url: string,
    logo_url: string,
    category: number
}

interface AvailableStoreProps {
    onStoreSelect: (storeId: number) => void;
}


const AvailableStores = ({ onStoreSelect }: AvailableStoreProps) => {
    const [availableStores, setAvailableStores] = useState<StoreType[]>([])
    
    useEffect(() => {
        axios.get('/base/stores').then((res) => {
            setAvailableStores(res.data)
        })
    }, [])

    const handleStoreClick = (storeId: number) => {
        onStoreSelect(storeId);
    }


    return (
        <div className="bg-white rounded-xl shadow-md overflow-hidden py-3 px-4 max-w-full">
            <h3 className="text-base font-medium mb-2">Obsługiwane sklepy</h3>
            <div className="flex flex-row space-x-2">
            {
                availableStores.map((store) => (
                    <div onClick={() => handleStoreClick(store.id)} className="bg-gray-200 rounded-lg p-2 hover:cursor-pointer hover:scale-105 hover:transition-transform">
                        <img src={ store.logo_url } alt={store.name} className="object-contain" />
                    </div>
                ))
            }
            </div>
        </div>
    );
}
 
export default AvailableStores;