import React from "react";

interface ModalProps {
  children: React.ReactNode,
  header: string,
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}


const Modal = ({ children, header, setDisplay }: ModalProps) => {
  return (
    <div id="feature-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-aut bg-gray-600 bg-opacity-60 overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="mx-auto relative p-4 w-8/12 max-h-full top-1/2 -translate-y-1/2">
        <div className="relative bg-gray-100 rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {header}
            </h3>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setDisplay(false)}>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4 md:p-5">
            {children}
            <a href="/logowanie" className="text-white inline-flex w-full justify-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Zaloguj się
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;