import { useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import { Menu } from "@headlessui/react";
import authSlice from "../../store/slices/auth";

function Nav() {
  const account = useSelector((state: RootState) => state.auth.account);
  console.log(account)
  const isLoggedIn = account !== null;
  // @ts-ignore
  const isStaff = account?.is_staff;
  console.log(isStaff)
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/logowanie");
  };

  return (
    <div>
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a className="text-lg font-bold text-slate-800 " href="/">PromoBoss</a>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4 text-slate-500">
                  <a href="/" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">
                    Strona główna
                  </a>

                  {/* <a href="/blog" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">
                    Blog
                  </a> */}

                  <a href="/promocje" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">
                    Promocje i Okazje
                  </a>

                  <a href="/kontakt" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">
                    Kontakt
                  </a>
                </div>
              </div>
            </div>
            {
              isLoggedIn
                ?
                <div className="inline-flex ">
                  <div className="notifications px-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          <i className="fa-regular fa-bell align-middle"></i>
                        </Menu.Button>
                      </div>
                      <Dropdown header="Powiadomienia" elements={[{ name: "test", href: "/test", content: "" }]} />
                    </Menu>
                  </div>
                  <div className="messages px-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          <i className="fa-regular fa-envelope align-middle"></i>
                        </Menu.Button>
                      </div>
                      <Dropdown header="Wiadomości" elements={[]} />
                    </Menu>
                  </div>

                  <div className="dropdown relative md:static ml-5" >
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="w-8 h-8 overflow-hidden rounded-full">
                          <img className="w-full h-full object-cover" src="/logo192.png" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {/* @ts-ignore */}
                            <h1 className='text-lg font-bold px-5 py-3'>Witaj, {account?.username}</h1>
                            <hr className='bg-slate-400' />
                            <Menu.Item>
                              <a href="#" className='hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm'>Profil</a>
                            </Menu.Item>
                            <Menu.Item>
                              <a href="/ogloszenia" className='hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm'>Zarządzaj ogłoszeniami</a>
                            </Menu.Item>
                            <Menu.Item>
                              <a href="/alerty" className='hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm'>Alerty</a>
                            </Menu.Item>
                            <Menu.Item>
                              <a href="/ustawienia" className='hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm'>Ustawienia</a>
                            </Menu.Item>
                            {isStaff &&
                              <Menu.Item>
                                <a href="/admin" className='bg-red-400 hover:bg-red-500 hover:text-gray-900 block px-4 py-2 text-sm'>Panel administracyjny</a>
                              </Menu.Item>
                            }

                            <Menu.Item>
                              <a href="#" className='hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm' onClick={handleLogout}>Wyloguj</a>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    {/* <button className="menu-btn focus:outline-none focus:shadow-outline flex flex-wrap items-center" onClick={ () => navigate("moje-konto")}>
                  <div className="w-8 h-8 overflow-hidden rounded-full">
                    <img className="w-full h-full object-cover" src="cdn/static/img/user.svg" />
                  </div> 
      
                  <div className="ml-2 capitalize flex ">
                    <h1 className="text-sm text-gray-800 font-semibold m-0 p-0 leading-none">{
                      // @ts-ignore
                    account?.username}</h1>
                    <i className="fad fa-chevron-down ml-2 text-xs leading-none"></i>
                  </div>                        
                </button> */}

                    <button className="hidden fixed top-0 left-0 z-10 w-full h-full menu-overflow"></button>

                    <div className="text-gray-500 menu hidden md:mt-10 md:w-full rounded bg-white shadow-md absolute z-20 right-0 w-40 mt-5 py-2 animated faster">

                      <a className="px-4 py-2 block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out" href="#">
                        <i className="fad fa-user-edit text-xs mr-1"></i>
                        edit my profile
                      </a>

                      <a className="px-4 py-2 block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out" href="#">
                        <i className="fad fa-inbox-in text-xs mr-1"></i>
                        my inbox
                      </a>

                      <a className="px-4 py-2 block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out" href="#">
                        <i className="fad fa-badge-check text-xs mr-1"></i>
                        tasks
                      </a>

                      <a className="px-4 py-2 block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out" href="#">
                        <i className="fad fa-comment-alt-dots text-xs mr-1"></i>
                        chats
                      </a>

                      <hr />

                      <a className="px-4 py-2 block capitalize font-medium text-sm tracking-wide bg-white hover:bg-gray-200 hover:text-gray-900 transition-all duration-300 ease-in-out" href="#">
                        <i className="fad fa-user-times text-xs mr-1"></i>
                        log out
                      </a>

                    </div>
                  </div>
                </div>
                :
                <div className="login-buttons">
                  <a href="/logowanie" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">Logowanie</a>
                  <a href="/rejestracja" className="transition ease-in-out delay-100 hover:bg-slate-500 hover:text-slate-50 hover:scale-110 duration-300 px-3 py-2 rounded-md text-sm font-medium">Rejestracja</a>
                </div>
            }

            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-slate-500 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Otwórz menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <hr className="bg-slate-300" />
                <a href="/" className="text-slate-600 block px-3 py-2 rounded-md text-base font-medium">
                  Strona główna
                </a>
                <a href="/o-nas" className="text-slate-500 block px-3 py-2 rounded-md text-base font-medium">
                  O PromoBoss
                </a>

                <a href="/sledzenie-produktow" className="text-slate-500 block px-3 py-2 rounded-md text-base font-medium">
                  Śledź produkty
                </a>

                <a href="/" className="text-slate-500 hover:bg-gray-700 block px-3 py-2 rounded-md text-base font-medium">
                  Kontakt
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Nav;
