import { useEffect, useState } from "react";

type ItemsProps = {
  items: string[],
  deleteHandler: Function,
}

const Items = ({ items, deleteHandler }: ItemsProps ) => {
  // const [itemsState, setItemsState] = useState<string[]>( [] );
  // useEffect(() => {
  //   setItemsState(items)
  // }, items)

  // const deleteHandler = (item: string) => {
    // items.splice(items.indexOf(item), 1)
  // }
  return (
    <>
  {
  items.map((item) => {
    return <div className="flex justify-between bg-indigo-500 p-2 rounded-xl text-sm my-1 font-medium hover:bg-indigo-400">
      <div className="bg-transparent border-none focus:bg-transparent focus:border-none text-slate-200 leading-relaxed tracking-wide px-2">{ item }</div>

      <button onClick={ () => { deleteHandler(item) } } type="button" className="text-slate-200 font-bold">
        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <button className="sr-only">Usuń</button>
      </button>
    </div>
    })
    }
    </>


  );
}
 
export default Items;