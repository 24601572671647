import React from "react";
import { stepProps } from "./types";


interface alertNameProps extends stepProps {
  setAlertName: React.Dispatch<React.SetStateAction<string>>,
}


const AlertName = ({ register, setNext, setAlertName }: alertNameProps) => {
  return (
    <div className="flex flex-col items-center">
      <input
        type="text"
        autoComplete="off"
        placeholder="Podaj nazwę nowego alertu"
        {...register("name", { required: true })}
        id="name"
        className="mt-5 w-10/12 rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
        onChange={(e) => {
          (e.target.value && e.target.value !== "") && setNext(true);
          (!e.target.value || e.target.value === "") && setNext(false)
          setAlertName(e.target.value)
        }}
      />
      <div className="text-center">
        <label className="text-sm text-slate-500 tracking-normal" htmlFor="name">Nazwa alertu widoczna jest tylko dla Ciebie. Umożliwia łatwiejsze zlokalizowanie alertu na stronie głównej i w <a className="text-indigo-500 hover:text-indigo-600 hover:underline" href="/moje-alerty">panelu alertów</a></label>
      </div>
    </div>
  );
}

export default AlertName;