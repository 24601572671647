import { stepProps } from "./types";

const Notifications = ({ register, setNext }: stepProps) => {
  const handleChange = (e: any) => {
    if (e.target.checked) {
      setNext(true);
    } else {
      setNext(false);
    }
  }

  return (
    <div className="flex items-center flex-col">
      <p className="text-center text-slate-600 my-2 font-medium text-lg">Wybierz metody powiadomień</p>

      <div className="my-4 grid grid-cols-3 space-x-2">
        <div className="flex flex-col items-center text-center">
          <input type="checkbox" id="tg" value="tg" className="hidden peer" {...register("notifications", {required: true, onChange(event) { handleChange(event) },})} />
          <label htmlFor="tg" className="flex flex-col items-center py-7 px-10 w-full rounded-lg border-2 text-blue-500 border-slate-400 cursor-pointer peer-checked:border-indigo-600 hover:bg-gray-50">                           
            <i className="fa-brands fa-telegram text-blue-500 leading-10 text-5xl"></i>
            <p className="mt-5 text-slate-600 font-bold text-xs">Telegram</p>
          </label>
        </div>

        <div className="flex flex-col items-center text-center">
          <input type="checkbox" id="email" value="email" className="hidden peer" {...register("notifications", {required: true, onChange(event) { handleChange(event) },})} />
          <label htmlFor="email" className="flex flex-col items-center py-7 px-10 w-full rounded-lg border-2 border-slate-400 cursor-pointer peer-checked:border-indigo-600 hover:bg-gray-50">                           
          <i className="fa-solid fa-envelope leading-10 text-5xl"></i>
            <p className="mt-5 text-slate-600 font-bold text-xs">E-mail</p>
          </label>
        </div>

        <div className="flex flex-col items-center text-center">
          <input type="checkbox" id="sms" value="sms" className="hidden peer" {...register("notifications", {required: true, onChange(event) { handleChange(event) },})} />
          <label htmlFor="sms" className="flex flex-col items-center py-7 px-10 w-full rounded-lg border-2 border-slate-400 cursor-pointer peer-checked:border-indigo-600 hover:bg-gray-50">                           
            <i className="fa-solid fa-comment-sms leading-10 text-5xl"></i>
            <p className="mt-5 text-slate-600 font-bold text-xs">SMS</p>
          </label>
        </div>

      </div>

      {/* { (isError && !errorReset) && "Należy wybrać minimum jeden serwis" } */}
    </div>
  );
}
 
export default Notifications;