import { Carousel, Typography, Button } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type AuctionData = {
  location: string
  main_photo: string
  olx_id: string
}

type Auction = {
  auction: number
  auction_data: AuctionData[]
  auction_store: number
  description: string
  found_at: string
  id: number
  price: number
  title: string
  url: string
}

const Shared = () => {
  const [auctions, setAuctions] = useState<Auction[]>([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auctions/shared/`).then((res) => {
        setAuctions(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const navigate = useNavigate()

  return (
    <div className="relative">
      <div className="mx-auto flex flex-col flex-wrap max-w-7xl items-center py-10 md:flex-row md:items-stretch md:justify-center md:py-12">
        <h2 className="w-full sm:text-5xl text-center text-4xl font-black tracking-wide">Najlepsze ogłoszenia udostępnione przez naszych użytkowników</h2>
        <p className="w-full text-center mt-4 max-w-xl text-sm font-medium leading-relaxed text-slate-500 md:text-base lg:text-lg">Ogłoszenia poniżej zostały znalezione przez naszych użytkowników z wykorzystaniem PromoBossa.</p>
        <div className="mt-10 w-full" style={{ height: '40rem' }}>
          <Carousel className="rounded-xl h-full">
            {auctions &&
              auctions.map(auction => (
                <div className="relative h-full w-full">
                  <img
                    src={auction.auction_data.at(0)?.main_photo}
                    alt="image 1"
                    className="h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                    <div className="w-3/4 text-center md:w-2/4">
                      <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                      >
                        {auction.title}
                      </Typography>
                      <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                      >
                        {auction.description.substring(0, 220)}...
                      </Typography>
                      <div className="flex justify-center gap-2">
                        <Button size="lg" color="white" onClick={() => navigate("/alert/" + encodeURIComponent(auction.title.toLowerCase().replaceAll(" ", "-")) + "-" + auction.id + "?public=true")}>
                          Sprawdź
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }

          </Carousel>
        </div>
      </div>
    </div>

  );
}

export default Shared;