import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "../store";
import authSlice from "../store/slices/auth";


export const getAccessToken = () => {
    return store.getState().auth.token;
}

const getRefreshToken = () => {
    const rf_token = store.getState().auth.refreshToken;
    if (rf_token === null || rf_token === undefined) {
        document.cookie // #TODO: retrieve refresh token from cookies
    }
    return rf_token
}

const axiosService = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + getAccessToken()
    },
});


// axiosService.interceptors.request.use(async (config) => {
//     const { token } = store.getState().auth;

//     if (token !== null) {
//         // @ts-ignore
//         config.headers.Authorization = 'Bearer ' + token;
//         console.debug('[Request]', config.url, JSON.stringify(token));
//     }
//     return config;
// });

// axiosService.interceptors.response.use(
//     (res) => {
//         console.debug('[Response]', res.config.url, res.status, res.data);
//         return Promise.resolve(res);
//     },
//     (err) => {
//         console.debug(
//             '[Response]',
//             err.config.url,
//             err.response.status,
//             err.response.data
//         );
//         return Promise.reject(err);
//     }
// );


// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const refreshToken = getRefreshToken();
    if (refreshToken !== null) {
        return axios
            .post(
                '/auth/refresh/',
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: process.env.REACT_APP_API_URL
                }
            )
            .then((resp) => {
                const { access, refresh } = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({ token: access, refreshToken: refresh })
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 400 || err.response.status === 401 ) {
                    store.dispatch(authSlice.actions.logout());
                }
            });
    }
    console.log(refreshToken)
    console.log()
};


// axiosService.interceptors.request.use((config: AxiosRequestConfig) => {
//     if (config.headers) {
//         config.headers.Authorization = "Bearer " + getAccessToken()
//     }
//     config.withCredentials = true;
//     return config
// })

axiosService.interceptors.request.use(
    async config => {
      const access_token = await getAccessToken()
      config.headers.set({
        'Authorization': `Bearer ${access_token}`,
        'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      })
      return config;
    },
    error => {
      Promise.reject(error)
  });
  
  // Response interceptor for API calls
  axiosService.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await getAccessToken();            
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosService(originalRequest);
    }
    return Promise.reject(error);
  });

createAuthRefreshInterceptor(
    axiosService,
    refreshAuthLogic
);

export function fetcher<T = any>(url: string) {
    return axiosService.get<T>(url).then((res) => res.data);
}

export default axiosService;