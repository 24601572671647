import { stepProps } from "../types";

const SelectALCategory = ({ register, setNext }: stepProps) => {
  return (
    <div className="flex flex-col items-center mt-2">
      <p className="text-slate-600 font-medium">Wybierz kategorię dla AllegroLokalnie</p>
      <div className="my-4 flex flex-col items-center w-full">
        <div className="w-full flex flex-col items-center text-center">
          <p>Wklej adres URL kategorii</p>
          <input
            { ...register("category_al") }
            type="text"
            placeholder="Np. https://allegrolokalnie.pl/oferty/konsole-i-automaty/sony-playstation-3-ps3-122284"
            id="al_category_url"
            className="mb-5 w-9/12 rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium"
            onChange={ (e) => {
              (e.target.value && e.target.value !== "") && setNext(true);
              (!e.target.value || e.target.value === "") && setNext(false)
            }}
          />
        </div>
        <div>
          <p>Będziemy szukać produktów w wybranej kategorii</p>
        </div>
      </div>
    </div>
  );
}
 
export default SelectALCategory;