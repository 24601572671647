import React, { ReactNode, Ref, SetStateAction, useState } from "react";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { FormikProps } from "formik";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import authSlice from '../store/slices/auth';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../store";
import Toast from "../components/toast/Toast";

interface FormikValues {
  email: string;
  recaptcha: string;
  password1: string;
  password2?: string;
  username?: string;
}

interface AuthProps {
  action: string;
  formik: FormikProps<FormikValues>;
  children: ReactNode;
  recaptchaRef: Ref<ReCAPTCHA>;
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>
  messages: Message[];
}

interface InputProps {
  type: string;
  name: string;
  placeholder: string;
  formik: FormikProps<any>;
  className?: string;
}

export interface Message {
  text: string;
  type: "success" | "danger" | "warning";
}

export const Input = ({ type, name, placeholder, formik, className }: InputProps) => {
  return <>
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      id={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      className={'w-full rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium' + (className && " " + className)}
    />
    {
      // @ts-ignore
      formik.errors[name] ? <div className='text-xs text-center text-red-600'>{formik.errors[name]} </div> : null
    }
  </>
}


const LoginExtra = () => {
  return (
    <>
      <p className='mt-6 text-center text-xs text-slate-500'><a className='border-dotted border-b border-slate-500' href='#'>Zapomniałeś hasła?</a></p>
      <p className='mt-8 text-center text-sm text-slate-500'>Nie masz konta? <a href="rejestracja" className='border-dotted border-b border-slate-500'>Zarejestruj się</a></p>
    </>
  )
}

const RegisterExtra = () => {
  return (
    <>
      <p className='mt-6 text-center text-xs text-slate-500 items-center max-w-xs mx-auto'>
        Zapoznałem się i akceptuję <a className='border-dotted border-b border-slate-500' href='/regulamin'>Regulamin</a> oraz <a className='border-dotted border-b border-slate-500' href='/polityka-prywatnosci'>Politykę Prywatności</a>
      </p>
      <p className='mt-8 text-center text-sm text-slate-500'>Masz już konto? <a href="logowanie" className='border-dotted border-b border-slate-500'>Zaloguj się</a></p>
    </>
  )
}

const Auth = ({ action, formik, children, recaptchaRef, loading, setLoading, messages }: AuthProps) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isLoggedIn = useSelector((state: RootState) => state.auth.account) !== null;

  const [sitekey, _] = useState(process.env.REACT_APP_RECAPTCHA_SITEKEY ? process.env.REACT_APP_RECAPTCHA_SITEKEY : "")
  const action_name = action === "login" ? "Zaloguj się" : "Zarejestruj się"

  const onChange = (value: any) => {
    formik.setFieldValue("recaptcha", value)
  }
  const google_logo = require('./google-logo.png');

  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: codeResponse => handleSocialLogin(codeResponse),
    flow: 'auth-code'
  })

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const handleSocialLogin = (codeResponse: Omit<CodeResponse, "error">) => {
    // TODO: check if codeResponse is without errors
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/google/`, codeResponse)
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setAccount(res.data.user));
        // setLoading(false);
        const referer = searchParams.get("ref") ? "/" + searchParams.get("ref") : "/"
        // console.log(referer)
        navigate(referer)
      })
      .catch((err) => {
        console.log(err);
        // setMessage(err.response.data.detail.toString());
      });
  };

  return (
    <div className="relative bg-indigo-500 font-medium text-white flex justify-center -m-8 min-h-full">
      <div className="m-0 flex max-w-7xl flex-1 flex-col lg:flex-row justify-center bg-white text-slate-800 shadow sm:rounded-lg sm:my-16 sm:mx-20">
        <div className='p-6 sm:p-12 lg:w-1/2 xl:w-5/12'>
          <div className='items-center flex flex-col'>
            <h1 className='text-2xl font-extrabold xl:text-3xl'>{action_name}</h1>
            <div className='w-full flex-1 mt-8'>
              <div className='flex flex-col items-center'>
                <button onClick={() => login()} className='mt-0 flex w-full max-w-xs items-center justify-center rounded-lg border py-3 text-sm font-semibold text-slate-700 bg-slate-100'>
                  <span className='rounded-full bg-white p-2'>
                    <img className='w-4' src={google_logo} alt="" />
                  </span>
                  <span className='ml-4'>{action_name} z kontem Google</span>
                </button>
              </div>
              <div className='relative my-12 border-b text-center'>
                <div className='absolute left-0 right-0 top-1/2 inline-block bg-transparent px-2 text-sm font-medium leading-4 tracking-wide text-slate-500 -translate-y-1/2'>
                  Lub {action_name} poprzez adres e-mail i hasło
                </div>
              </div>
              <form className='mx-auto max-w-xs' onSubmit={formik.handleSubmit}>
                {children}
                <ReCAPTCHA
                  className='mt-3 mx-auto w-max'
                  ref={recaptchaRef}
                  sitekey={sitekey}
                  onChange={onChange}
                />
                {formik.errors.recaptcha ? <div className='text-xs text-center text-red-600'>{formik.errors.recaptcha} </div> : null}
                <button className='g-recaptcha mt-5 flex w-full items-center justify-center rounded-lg bg-indigo-500 py-4 font-semibold tracking-wide text-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="-ml-2 h-6 w-6 icon"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                  <span className='ml-3'>{action_name}</span>
                </button>
              </form>
              {action === "login" && <LoginExtra />}
              {action === "register" && <RegisterExtra />}
            </div>
          </div>
        </div>
        <div className='text-center bg-slate-200 flex-1 justify-center sm:rounded-tr-lg sm:rounded-br-lg lg:flex'>
          <div className='lg:m-16 w-full m-12 max-w-sm bg-contain bg-center bg-no-repeat' style={{ backgroundImage: "url(" + "https://treact.owaiskhan.me/static/media/login-illustration.39a64f784d3474ef7b31bd14ad6360c7.svg" + ")" }}></div>
        </div>
      </div>
      {messages && messages.map((message: Message) => <Toast message={message.text} type={message.type} />)}
    </div>
  );
}

export default Auth;
