import { useEffect, useState } from "react";
import { auctionType, foundAuctionType } from "../../user/auctions/types";
import axiosService from "../../utils/axios";

type offersType = {
  [x: number]: foundAuctionType[]
}

const Alerts = () => {
  const [alerts, setAlerts] = useState<auctionType[]>([]);
  const [offers, setOffers] = useState<offersType>({});
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const offersRaw: offersType = {}


  useEffect(() => {
    setAlerts(alerts);
  }, [offers])

  const getOffers = (alert_pk: number) => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/auctions/found/${alert_pk}/?source=homepage`)
      .then(
        (res) => {
          const data = res.data as foundAuctionType[];
          setOffers({ [alert_pk]: data });
        }
      ).catch((error) => {
        console.log("Error:", error)
      })
  }


  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/auctions/`)
      .then(
        (res) => {
          setAlerts(res.data);
        }
      ).catch((error) => {
        console.log("Error:", error)
      })
  }, [])

  useEffect(() => {
    if (alerts.length > 0) {
      // getOffers()
      fetchOffers(alerts[0].pk)
    }
  }, [alerts])

  const fetchOffers = (alert_pk: number) => {
    setActiveTab(alert_pk);
    setLoadingOffers(true);
    getOffers(alert_pk);
    setLoadingOffers(false);
  }


  return (
    <div className="relative">
      <div className="mx-auto max-w-7xl py-6 lg:py-6 my-10 px-6 bg-white rounded-3xl">
        <div className="flex flex-col items-center justify-between xl:flex-row">
          <div className="flex flex-col px-4">
            <h2 className="text-center font-black text-4xl tracking-wide sm:text-5xl text-slate-700 xl:w-80">Twoje alerty</h2>
            <div className="p-2 flex flex-row justify-between w-full">
              <a href="moje-alerty" className="text-blue-400 underline hover:font-semibold hover:text-blue-500 hover:cursor-pointer">Zarządzaj alertami</a>
              <a href="dodaj-alert" className="text-blue-400 underline hover:font-semibold hover:text-blue-500 hover:cursor-pointer">Dodaj nowy alert</a>

            </div>
          </div>
          {
            alerts ?
              <div className="flex mt-12 flex-wrap rounded bg-slate-200 p-2 leading-4 xl:mt-0">
                {
                  alerts.map((alert) => (
                    <div
                      key={alert.pk}
                      id={"alert-" + alert.pk}
                      className={`text-slate-500 font-medium text-sm text-center py-3 px-6 rounded-sm cursor-pointer w-1/2 mt-2 sm:mt-0 sm:mr-2 sm:w-auto sm:text-base ${activeTab === alert.pk ? "bg-indigo-500 hover:bg-indigo-600 text-slate-200" : "hover:bg-slate-300"}`}
                      onClick={() => { fetchOffers(alert.pk) }}
                    >
                      {alert.name}
                    </div>
                  ))
                }
              </div>
              :
              <div>null</div>
          }
        </div>
        {/* <div className="mt-2">
            <a>Zarządzaj alertami &gt;</a>
        </div> */}
        {
          alerts.length > 0 && alerts.map((alert) => (
            <div
              id={"offers-" + alert.pk}
              className={`flex flex-wrap items-stretch mt-6 sm:mr-10 md:mr-6 lg:-mr-12 ${activeTab === alert.pk ? "opacity-100" : "opacity-0 hidden"}`}
            >

              {alert.pk in offers && offers[alert.pk].map((offer) => (
                <div className="w-full mt-10 sm:pr-10 sm:w-1/2 md:w-1/3 md:pr-6 lg:pr-12 h-full">
                  <a href={"alert/" + encodeURIComponent(offer.title.toLowerCase().replaceAll(" ", "-")) + "-" + offer.id} className="sm:max-w-none sm:mx-0 mx-auto block max-w-xs rounded-br rounded-bl bg-slate-100">
                    <div className="xl:h-64 relative bg-cover bg-center h-56 rounded-tl rounded-tr" style={{ backgroundImage: "url(" + (offer.main_photo ? offer.main_photo : "/img/product_placeholder.jpg") + ")" }} >
                      <div className="absolute bottom-0 left-0 ml-4 mb-4 inline-flex rounded-full items-end bg-slate-500 leading-4">
                        {offer?.location && <div className="mr-1 flex items-center text-sm font-bold py-2 px-3 w-full text-slate-200">
                          {offer?.location}
                        </div>}
                      </div>
                    </div>
                    <div className="p-4 bg-slate-200 relative">
                      <h5 className="text-lg font-semibold h-12 truncate ...">{offer.title}</h5>
                      {offer.description && (<p className="mt-1 text-sm font-medium text-slate-500">{offer.description.substring(0, 96) + "..."}</p>)}
                      <p className="mt-4 font-bold text-xl">{offer.price} zł</p>
                      <div className="absolute bottom-0 right-0 mr-3 mb-3 inline-flex text-xs font-medium text-slate-500">{offer.found_at}</div>
                    </div>
                  </a>
                </div>
              ))}

              <a className="text-right w-full sm:pr-10 md:pr-6 lg:pr-12 text-blue-500 hover:text-blue-600 hover:cursor-pointer">Zobacz więcej z tego alertu...</a>
            </div>
          ))

        }
      </div>
    </div>
  );
}

export default Alerts;