import { useEffect, useState } from "react";
import { auctionType, foundAuctionType } from "../user/auctions/types";
import axiosService from "../utils/axios";


const MyAlerts = () => {
  const [alerts, setAlerts] = useState<auctionType[]>( [] );
  const [offers, setOffers] = useState<foundAuctionType[]>(  );
  const [activeAlert, setActiveAlert] = useState<string>();
  const [loadingOffers, setLoadingOffers] = useState(false);

  const getOffers = (alert_pk: number) => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/auctions/found/${alert_pk}/?source=homepage`).then(
      (res) => {
        const data = res.data as foundAuctionType[];
        setOffers(data);
      }
    )
  }

  useEffect(() => {
    setAlerts(alerts);
  }, [offers])

  useEffect(() => {
    axiosService.get(`${process.env.REACT_APP_API_URL}/auctions/`).then(
      (res) => {
        setAlerts(res.data);
      }
    )
  }, [])

  useEffect(() => {
      if (alerts.length > 0) {
        // getOffers()
        fetchOffers(alerts[0].pk)
      } 
  }, [alerts])

  const fetchOffers = (alert_pk: number, alert_name?: string) => {
    alert_name && setActiveAlert(alert_name);
    getOffers(alert_pk)
  }
  

  return (
    <div className="relative">
    <div className="mx-auto text-center py-6 lg:py-6 my-10 px-6 bg-white rounded-3xl">
      <div className="flex flex-col items-center justify-between xl:flex-row mb-8">
        <h2 className="font-black text-4xl tracking-wide sm:text-5xl text-slate-700 text-center mx-auto">Twoje alerty</h2>
      </div>
      <div className="flex flex-row divide-x w-full">
          <div className="w-2/12">
            <div className="mb-2">
              <input type="text" placeholder="Wyszukaj" className="text-center p-2 border-slate-200 border rounded-3xl" />
            </div>
            <div className="flex flex-col">
            {
              alerts && alerts.map(
                (alert) => <a className="text-slate-700 leading-7 hover:underline hover:cursor-pointer" onClick={ () => fetchOffers(alert.pk, alert.name) }>{ alert.name }</a>
              )
            }
            </div>
          </div>
          <div className="mx-auto max-w-7xl py-6 lg:py-6 px-6 bg-white rounded-3xl">
            <div>
              <h3 className="text-lg font-medium leading-6 text-center">{ activeAlert }</h3>
            </div>

            <div className="flex flex-wrap items-stretch sm:mr-10 md:mr-6 lg:-mr-12 opacity-100">
              { offers && offers.map((offer) => 
                <div className="w-full mt-10 sm:pr-10 sm:w-1/2 md:w-1/3 md:pr-6 lg:pr-12 h-full">
                  <a href={ "alert/" + encodeURIComponent(offer.title.toLowerCase().replaceAll(" ", "-")) + "-" + offer.id } className="sm:max-w-none sm:mx-0 mx-auto block max-w-xs rounded-br rounded-bl bg-slate-100">
                    <div className="xl:h-64 relative bg-cover bg-center h-56 rounded-tl rounded-tr" style={{ backgroundImage: "url(" + (offer.main_photo ? offer.main_photo : "/img/product_placeholder.jpg") + ")" }} >
                      <div className="absolute bottom-0 left-0 ml-4 mb-4 inline-flex rounded-full items-end bg-slate-500 leading-4">
                        { offer?.location && <div className="mr-1 flex items-center text-sm font-bold py-2 px-3 w-full text-slate-200">
                          { offer?.location }
                        </div> }
                      </div>
                    </div>
                    <div className="p-4 bg-slate-200 relative">
                      <h5 className="text-lg font-semibold h-12 truncate ...">{ offer.title }</h5>
                      { offer.description && (<p className="mt-1 text-sm font-medium text-slate-500">{ offer.description.substring(0, 96) + "..." }</p>) }
                      <p className="mt-4 font-bold text-xl">{ offer.price } zł</p>
                      <div className="absolute bottom-0 right-0 mr-3 mb-3 inline-flex text-xs font-medium text-slate-500">{ offer.found_at }</div>
                    </div>
                  </a>
                </div>
              )}
            </div>

            <div className="mt-4">
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <a href="#" className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Previous</span>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="#" aria-current="page" className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20">1</a>
              <a href="#" className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">2</a>
              <a href="#" className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex">3</a>
              <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">...</span>
              <a href="#" className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex">8</a>
              <a href="#" className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">9</a>
              <a href="#" className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">10</a>
              <a href="#" className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Next</span>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
              </a>
            </nav>
            </div>
          </div>

        </div>
    </div>
    </div>
  );
}
 
export default MyAlerts;