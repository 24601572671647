import { useEffect, useState } from "react";
import Items from "./Items";
import { stepProps } from "./types";

const IdentifyPhrases = ({ register, setValue, setNext }: stepProps) => {
    const [identifyPhrases, setidentifyPhrases] = useState<string[]>( [] );
    const [identifyPhrase, setidentifyPhrase] = useState<string>("");
    const addItem = (b: any) => {
      b.preventDefault();
      if (!identifyPhrase || identifyPhrases.includes(identifyPhrase)) {
        return;
      }
      setidentifyPhrases([...identifyPhrases, ...[...new Set(identifyPhrase.split(",").map(el => el.trim().replace('"', '')).filter(el => el !== ""))] ])
      setidentifyPhrase("");
      // formik.setFieldValue("search_phrases", [...searchPhrases, searchPhrase])
      return;
    }
  
    const handleItemDelete = (item: string) => {
      let s = identifyPhrases;
      s.splice(identifyPhrases.indexOf(item), 1);
      setidentifyPhrases([ ...s ])
    }

    useEffect(() => {
      setValue && setValue('identify_phrases', identifyPhrases);
      if (identifyPhrases && identifyPhrases.length > 0) {
        setNext(true);
      } else {
        setNext(false);
      }
    }, [identifyPhrases])

    return (
      <>
      <p className="text-slate-600 text-lg text-center mt-6">Frazy identifykujące</p>
      <div className="flex lg:flex-row flex-col">
        <div className="flex flex-col items-center lg:w-9/12">
          <div className="flex flex-row items-center justify-center space-x-2 mt-5 w-11/12">
            <input
              type="text"
              autoComplete="off"
              placeholder="Dodaj frazy identyfikujące"
              name="identify_phrase" value={identifyPhrase}
              id="identify_phrase"
              className="w-full rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium text-center"
              onChange={ (e) => setidentifyPhrase(e.target.value) }
            />
            <input
              type="hidden"
              { ...register("identify_phrases") }
              value={ identifyPhrases }
            />
            <button onClick={ (e) => { addItem(e) } } className="bg-indigo-600 rounded-md py-3 px-4 text-slate-200 font-bold text-lg">+</button>
          </div>

          <div className="text-center flex items-center flex-col">
            <label className="text-sm text-slate-500 tracking-normal w-10/12" htmlFor="name">Fraza identyfikująca to zwrot w tytule lub opisie, który określa, czy dane ogłoszenie kwalifikuje się do wysłania powiadomienia. Wielkośc liter nie ma znaczenia. Elementy możesz dodawać pojedyńczo lub po przecinku.</label>
          </div>
  
          <div className="text-center flex items-center w-full flex-col mt-8 mb-3">
            <hr className="text-slate-600 bg-slate-600 w-full" />
            <p className="text-sm text-slate-500 tracking-normal mt-2 w-8/12"><span className="text-indigo-500 font-semibold">Przykład: </span>Szukasz konsoli PlayStation 3. Jako frazy identyfikujące możesz wykorzystać np. "playstation 3", "playstation3", "ps3", "ps 3"</p>
          </div>
  
        </div>
        <div className="wflex flex-row items-center p-4 lg:border-l-slate-500 border-l">
          <p className="text-center">Aktualne frazy identyfikujące:</p>
          { identifyPhrases && <Items items={ identifyPhrases } deleteHandler={ handleItemDelete }/> }
        </div>
      </div>
  
    </>
    );
  }
 
export default IdentifyPhrases;