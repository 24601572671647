type locationProps = {
  city: string,
  region: string,
  lat: number,
  lon: number,
}

const Location = ({ city, region, lat, lon }: locationProps) => {
  return (
    <div className="bg-white items-center text-center">
    <div className="pt-8">Lokalizacja</div>
    <div className="text-slate-700">{ city + ", " + region }</div>
    <iframe
      className="border-0 w-full h-48"
      loading="lazy"
      src={ `https://maps.google.com/maps?q=${lat},${lon}&z=8&ie=UTF8&iwloc=&output=embed` }>
    </iframe>
  </div>
  );
}
 
export default Location;