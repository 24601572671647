import { useSelector } from "react-redux";
import Homepage from "../Homepage";
import FAQ from "./FAQ";
import First from "./features/First";
import Second from "./features/Second";
import Hero from "./Hero";
import Pricing from "./Pricing";
import Pros from "./Pros";
import Shared from "./Shared";
import Steps from "./Steps";
import Testimonials from "./Testimonials";
import { RootState } from "../store";

const LandingPage = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.account) !== null;
  if (isLoggedIn) {
    return <Homepage />
  }
  return (
    <>
      <Hero />
      <Shared />
      <First />
      <Second />
      <Steps />
      <Pros />
      <Pricing />
      <FAQ />
    </>
  );
}
 
export default LandingPage;