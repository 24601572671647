import { useEffect, useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import { Spinner } from "@material-tailwind/react";


interface Feature {
  title: string;
  description?: string;
  badge?: string;
}


interface Data {
  id: number;
  name: string;
  active: boolean;
  created: string;
  features: Feature[]
}

interface ListingData {
  title: string;
  badge?: string;
  description?: string;
}


const ListingItem = ({ title, badge, description }: ListingData) => {
  return (
    <li className="mb-10 ms-8">
      <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">📣</span>
      <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
        {title}
        <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">{badge}</span>
      </h3>
      <p className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">{description}</p>
    </li>
  )
}

const FeatureModal = () => {
  const [display, setDisplay] = useState(JSON.parse(localStorage.getItem("show-features-modal") || "true") !== false);
  const [data, setData] = useState<Data>();
  const [loading, setLoading] = useState(true);
  const [header, setHeader] = useState("Ładowanie")

  useEffect(() => {
    if (!data) return;
    setHeader("🎉 " + data?.name)
  }, [loading, data])

  useEffect(() => {
    if (display) {
      axios.get(process.env.REACT_APP_API_URL + "/content/new-features/").then((response) => {
        setData(response.data[0]);
        setLoading(false);
      })
      return;
    };
    localStorage.setItem('show-features-modal', 'false');
  }, [display])

  return (
    <>
      {display &&
        <Modal setDisplay={setDisplay} header={header}>
          {
            loading && <Spinner className="mx-auto" />
          }
          <ol className="relative border-s border-gray-400 dark:border-gray-600 ms-3.5 mb-4 md:mb-5">
            {data && data.features.map((feature) => (
              <ListingItem title={feature.title} description={feature.description} badge={feature.badge} />
            ))}
          </ol>
        </Modal>
      }
    </>
  );
}

export default FeatureModal;