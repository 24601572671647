import { useRef, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import authSlice from '../store/slices/auth';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import Auth, { Input, Message } from './Auth';



const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      email: "",
      password1: "",
      recaptcha: ""
    },
    onSubmit: (values) => {
      if (!values.recaptcha) {
        formik.setFieldError('recaptcha', 'Captcha jest wymagana.');
        return;
      }
      setLoading(true);
      handleLogin(values.email, values.password1)
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Adres e-mail jest wymagany!"),
      password1: Yup.string().trim().required("Hasło jest wymagane!"),
      recaptcha: Yup.string().when('isSubmitted', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string()
      })
    })
  });

  const handleLogin = (email: string, password: string) => {
    const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
    axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login/`,
      { email, password, recaptchaValue }
    )
      .then((res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.access,
            refreshToken: res.data.refresh,
          })
        );
        dispatch(authSlice.actions.setAccount(res.data.user));
        setMessages(oldMessages => [...oldMessages, { text: "Pomyślnie zalogowano. Za chwilę nastąpi przekierowanie", type: "success" }])
        setLoading(false);
        const referer = searchParams.get("ref") ? "/" + searchParams.get("ref") : "/"
        navigate(referer)
      })
      .catch((err) => {
        recaptchaRef.current && recaptchaRef.current.reset()
        let errors: Message[] = []
        Object.keys(err.response.data).forEach(key => {
          errors.push({ text: err.response.data[key] as string, type: "danger" })
        })
        setMessages(oldMessages => [...oldMessages, ...errors]);
      });
  };

  return (
    <Auth action='login' formik={formik} recaptchaRef={recaptchaRef} loading={loading} setLoading={setLoading} messages={messages}>
      <Input type="email" name="email" placeholder='E-mail' formik={formik} />
      <Input type="password" name="password1" placeholder='Hasło' formik={formik} className='mt-3' />
    </Auth>
  );
}

export default Login;
