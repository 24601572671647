import { useFormik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Auth, { Input, Message } from "./Auth";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  let ready_email: string = "";
  if (location.state?.email) {
    ready_email = location.state.email;
  }

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  const handleRegister = (email: string, username: string, password1: string, password2: string) => {
    const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
    axios.post(
      `${process.env.REACT_APP_API_URL}/auth/register/`,
      { email, password1, password2, username, recaptchaValue }
    )
      .then((res) => {
        setLoading(false);
        setMessages(oldMessages => [...oldMessages, { text: "Pomyślnie zarejestrowano. Na podany adres e-mail została wysłana wiadomość weryfikacyjna", type: "success" }])
        setTimeout(() => {
          navigate("/potwierdz-email")
        }, 2500)
      })
      .catch((err) => {
        console.log(err);
        let errors: Message[] = []
        Object.keys(err.response.data).forEach(key => {
          errors.push({ text: err.response.data[key] as string, type: "danger" })
        })
        setMessages(oldMessages => [...oldMessages, ...errors]);
      });
  }

  const formik = useFormik({
    initialValues: {
      email: ready_email,
      username: "",
      password1: "",
      password2: "",
      recaptcha: ""
    },
    onSubmit: (values) => {
      if (!values.recaptcha) {
        formik.setFieldError('recaptcha', 'Captcha jest wymagana.');
        return;
      }
      setLoading(true);
      handleRegister(values.email, values.username, values.password1, values.password2);
    },

    validationSchema: Yup.object({
      email: Yup.string().trim().required("Adres e-mail jest wymagany!"),
      username: Yup.string().trim().required("Nazwa użytkownika jest wymagana!"),
      password1: Yup.string().trim().required("Hasło jest wymagane!"),
      password2: Yup.string().trim().required("Hasło jest wymagane!"),
      recaptcha: Yup.string().when('isSubmitted', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string()
      })
    })
  });

  return (
    // @ts-ignore
    <Auth action='register' formik={formik} recaptchaRef={recaptchaRef} messages={messages}>
      <Input name="username" type="text" placeholder="Nazwa użytkownika" formik={formik} />
      <Input name="email" type="email" placeholder="Adres e-mail" formik={formik} className="mt-3" />
      <Input name="password1" type="password" placeholder="Hasło" formik={formik} className="mt-3" />
      <Input name="password2" type="password" placeholder="Powtórz hasło" formik={formik} className="mt-3" />
    </Auth>
  )
}

export default Register;
