const Pricing = () => {
  const standard_plan = {
    "name": "standard",
    "price": 9.99,
    "for_who": "Dla każdego",
    "specifications": [
      "lorem ipsum",
      "lorem ipsum",
      "lorem ipsum",
      "lorem ipsum"
    ]
  }


  return (
    <>
      <div className="relative">
        <div className="py-20 max-w-7xl mx-auto lg:py-24">
          <div className="flex flex-col items-center mt-10 w-full">
            <h5 className="text-indigo-500 font-bold mb-4"></h5>
            <h2 className="text-center text-4xl font-black tracking-wide sm:text-5xl w-full">
              Pakiety PromoBoss <span className="text-indigo-500">Premium</span>
            </h2>
            <p className="text-slate-500 leading-relaxed font-medium text-sm max-w-xl mt-4 md:text-base lg:text-lg w-full text-center">
            Mamy w planach wprowadzić pakiety, które zaoferują Ci dodatkowe funkcje i wyjątkowe korzyści. Pozostań z nami, aby dowiedzieć się więcej.
            </p>
          </div>

          <div className="flex flex-col relative justify-between items-center lg:flex-row lg:items-stretch">
            <div className="relative mt-16 flex flex-col max-w-sm rounded-lg px-8 pt-2 text-center shadow lg:mr-8 opacity-40 select-none">
              <div className="absolute left-0 right-0 top- h-2 rounded-l-lg rounded-r-lg bg-gradient-to-r from-emerald-600 to-emerald-300"></div>
              <div className="flex flex-col py-8 uppercase leading-relaxed">
                <span className="text-xl font-bold">Personal</span>
                <span className="font-bold text-4xl my-1 sm:text-5xl">PLN 9.99</span>
                <span className="text-slate-400 tracking-widest font-bold">Miesięcznie</span>
              </div>
              <div className="flex flex-col -mx-8 flex-1 border-t-2 border-b-2 border-slate-300 p-8">
                <span className="mt-0 tracking-wide font-bold text-xl">Dla wymagających</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
              </div>
              <div className="py-8 px-4 sm:px-8 xl:px-16">
                <button className="bg-gradient-to-r from-emerald-600 to-emerald-300 text-slate-100 w-full rounded-full text-sm py-4 tracking-wider uppercase cursor-pointer font-bold px-8">Wybierz</button>
              </div>
            </div>
            
            <div className="relative mt-16 flex flex-col max-w-sm rounded-lg px-8 pt-2 text-center shadow lg:mr-8 bg-indigo-500 text-white">
              <div className="flex flex-col py-8 uppercase leading-relaxed">
                <span className="text-xl font-bold">Standard</span>
                <span className="font-bold text-4xl my-1 sm:text-5xl">PLN 0.00</span>
                <span className="text-slate-50 tracking-widest font-bold">Miesięcznie</span>
              </div>
              <div className="flex flex-col -mx-8 flex-1 border-t-2 border-b-2 border-slate-300 p-8">
                <span className="mt-0 tracking-wide font-bold text-xl">Dla każdego</span>
                <span className="text-slate-50 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-50 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-50 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-50 mt-5 font-medium">lorem ipsum</span>
              </div>
              <div className="py-8 px-4 sm:px-8 xl:px-16">
                <button className="bg-gradient-to-r text-indigo-500 bg-white w-full rounded-full text-sm py-4 tracking-wider uppercase cursor-pointer font-bold px-8">Wybierz</button>
              </div>
            </div>
            
            <div className="relative mt-16 flex flex-col max-w-sm rounded-lg px-8 pt-2 text-center shadow lg:mr-8 opacity-40 select-none">
              <div className="absolute left-0 right-0 top- h-2 rounded-l-lg rounded-r-lg bg-gradient-to-r from-red-500 to-red-300"></div>
              <div className="flex flex-col py-8 uppercase leading-relaxed">
                <span className="text-xl font-bold">Personal</span>
                <span className="font-bold text-4xl my-1 sm:text-5xl">PLN 49.99</span>
                <span className="text-slate-400 tracking-widest font-bold">Miesięcznie</span>
              </div>
              <div className="flex flex-col -mx-8 flex-1 border-t-2 border-b-2 border-slate-300 p-8">
                <span className="mt-0 tracking-wide font-bold text-xl">Dla wymagających</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
                <span className="text-slate-500 mt-5 font-medium">lorem ipsum</span>
              </div>
              <div className="py-8 px-4 sm:px-8 xl:px-16">
                <button className="bg-gradient-to-r from-red-500 to-red-300 text-slate-100 w-full rounded-full text-sm py-4 tracking-wider uppercase cursor-pointer font-bold px-8">Wybierz</button>
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </>
  );
}
 
export default Pricing;