import { stepProps } from "../types";

const SelectVintedCategory = ({ register, setNext }: stepProps) => {
  return (
    <div className="flex flex-col items-center mt-2">
      <p className="text-slate-600 font-medium">Wybierz kategorię dla Vinted</p>
      <div className="my-4 flex flex-col items-center w-full">
        <div className="w-full flex flex-col items-center text-center">
          <div className="mb-2">
            <p>1. Przejdź na stronę <a className="text-blue-500 hover:underline" href="https://vinted.pl">Vinted</a></p>
            <p>2. Wyszukaj to, czego szukasz korzystając z wyszukiwarki, filtrów, kategorii</p>
            <p>3. Skopiuj link i wklej go w polu poniżej</p>
          </div>
          <input
            {...register("vinted_urls")}
            type="text"
            placeholder="https://www.vinted.pl/catalog?catalog[]=3025&order=newest_first&search_text=playstation%204&time=1725459996&search_id=16728413032&video_game_platform_ids[]=1280&price_from=150&currency=PLN&price_to=450"
            id="al_category_url"
            className="mb-5 w-9/12 rounded-lg border bg-slate-100 py-4 px-8 text-sm font-medium"
            onChange={(e) => {
              (e.target.value && e.target.value !== "") && setNext(true);
              (!e.target.value || e.target.value === "") && setNext(false)
            }}
          />
        </div>
        <div>
          <p>Będziemy szukać produktów w kategorii oraz filtrach które wybrałeś na Vinted.</p>
        </div>
      </div>
    </div>
  );
}

export default SelectVintedCategory;