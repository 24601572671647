import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>();
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row py-20 items-center lg:py-24 mx-auto leading-6 max-w-7xl">
        <div className="mx-auto lg:w-5/12 lg:max-w-none lg:text-left text-center max-w-7xl">
          <h1 className="lg:text-4xl text-3xl leading-5 font-bold text-gray-900">Dołącz do PromoBossa</h1>
          <p className="my-5 lg:my-8 text-base">
            Zarejestruj się i zyskaj dostęp do niesamowitych funkcjonalości serwisu. Nasi użytkownicy mogą dodawać alerty wyszukiwania na serwisach ogłoszeniowych, są powiadamiani jako pierwsi o interesujących promocjach w sklepach i wiele innych!
          </p>
          <div className="lg:mx-0 mt-12 max-w-md relative text-center mx-auto">
            <input className="sm:py-5 sm:pr-48 w-full rounded-full border-2 pl-8 py-4 font-medium" type="text" placeholder="Twój adres e-mail" onChange={ (e) => handleChange(e) } />
            <button
              className="sm:absolute sm:my-2 mr-2 sm:w-40 sm:leading-4 right-0 top-0 w-full bottom-0 my-4 flex justify-center items-center rounded-full py-4 font-bold bg-indigo-500 text-slate-100"
              onClick={ () => navigate('rejestracja', {state: {email: email}}) }
            >
              Zaczynajmy!
            </button>
          </div>
          <div className="lg:mt-20 mt-12">

          </div>
        </div>
        <div className="lg:mt-0 lg:self-end relative flex flex-col flex-1 justify-center mt-12">
          <div className="lg:justify-end flex items-center justify-center">
            <img className="w-full min-w-0 max-w-lg h-auto align-middle" src="https://treact.owaiskhan.me/static/media/design-illustration-2.6da6a00b20c07c4a9b65d1870679e1b8.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Hero;