import { ReactNode, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Introduction from "./Introduction";
import SelectALCategory from "./selectStores/SelectALCategory";
import SelectOLXCategory from "./selectStores/SelectOLXCategory";
import SelectStore from "./selectStores/SelectStore";
import IdentifyPhrases from "./IdentifyPhrases";
import SearchPhrases from "./SearchPhrases";
import AlertName from "./AlertName";
import BlockedPhrases from "./BlockedPhrases";
import Price from "./Price";
import Notifications from "./Notifications";
import Summary from "./Summary";
import { FormValues } from "./types";
import axiosService from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import SelectVintedCategory from "./selectStores/SelectVintedCategory";
import IntegrationAi from "./IntegrationAI/IntegrationAI";


const Step = ({ children, currentStep, stepNumber }: { children: ReactNode, currentStep: number, stepNumber: number }) => {
  return (
    <div className={currentStep === stepNumber ? "transform transition-all duration-500 ease-in-out relative left-0" : "absolute left-full opacity-0 invisible"}>
      {children}
    </div>
  )
}


const AddAlert = () => {
  const { register, handleSubmit, getValues, setValue } = useForm<FormValues>();

  const [currentStep, setCurrentStep] = useState(0)
  const [alertName, setAlertName] = useState<string>("")
  const [summaryAvailable, setSummaryAvailable] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStores, setSelectedStores] = useState<string[]>([])

  useEffect(() => {
    if (currentStep !== 2) return;
    if (selectedStores.length > 0) {
      setCanNext(true)
    } else {
      setCanNext(false)
    }
  }, [selectedStores, canNext])

  const maxStep = 12
  const handleStepChange = (e: any, value: number) => {
    e.preventDefault();
    if (value > 0) setCanNext(false);
    let expected_value = currentStep + value

    const declaredStores = ["olx", "al", "vinted"]
    if (value < 0 && [3, 4, 5].includes(currentStep)) {
      for (let i = currentStep - 1; i >= 3; i--) {
        const store = declaredStores[i - 3]
        if (selectedStores.includes(store)) {
          setCurrentStep(i);
          return;
        }
      }
      setCurrentStep(2)
      return;
    }
    for (let i = 3; i <= 5; i++) {
      if (expected_value === i && !selectedStores.includes(declaredStores[i - 3])) {
        expected_value += value;
      }
    }

    // Skip search phrases and min-max price form if only vinted store is selected
    if ((expected_value === 6 || expected_value === 9) && selectedStores.length == 1 && selectedStores[0] === "vinted") {
      expected_value += value;
    }

    if (expected_value >= 0 && expected_value <= maxStep) {
      setCurrentStep(expected_value);
    }
  }
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = (data: any, e: any) => {
    e.preventDefault();
    console.log(data)

    setLoading(true);
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/auctions/`, data = data)
      .then((res) => {
        console.log("success")
        setLoading(false);
        navigate("/")
      })
      .catch((err) => {
        console.debug(err);
      });
  }

  const onError = (e: any) => {
    console.log(e)
    console.log("errorrrr")
  }

  useEffect(() => {
    if (currentStep === maxStep) {
      setSummaryAvailable(true);
    }
    if (currentStep === 3 || currentStep === 8) {
      setCanNext(true);
    }
  }, [currentStep])

  return (
    <div className="relative">
      <div className="mx-auto max-w-7xl py-6 my-10 lg:px-6 px-1 bg-white rounded-3xl">
        <h2 className="text-center font-black text-4xl tracking-wide sm:text-5xl text-slate-700 mb-3">Dodaj alert</h2>
        <div>{currentStep === 0 && <Introduction />}</div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Step currentStep={currentStep} stepNumber={1}><AlertName register={register} setNext={setCanNext} setAlertName={setAlertName} /></Step>
          <Step currentStep={currentStep} stepNumber={2}><SelectStore register={register} setSelectedStores={setSelectedStores} /></Step>
          <Step currentStep={currentStep} stepNumber={3}>{currentStep === 3 && <SelectOLXCategory register={register} setValue={setValue} setNext={setCanNext} />}</Step>
          <Step currentStep={currentStep} stepNumber={4}><SelectALCategory register={register} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={5}><SelectVintedCategory register={register} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={6}><SearchPhrases register={register} setValue={setValue} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={7}><IdentifyPhrases register={register} setValue={setValue} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={8}><BlockedPhrases register={register} setValue={setValue} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={9}><Price register={register} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={10}><IntegrationAi register={register} setNext={setCanNext} alertName={alertName} /></Step>
          <Step currentStep={currentStep} stepNumber={11}><Notifications register={register} setNext={setCanNext} /></Step>
          <Step currentStep={currentStep} stepNumber={12}><Summary getValues={getValues} setStep={setCurrentStep} /></Step>

          <div className="w-full flex flex-row justify-center mt-4 lg:space-x-2 space-x-1 items-center">
            {
              currentStep === 0 &&
              <button className="p-3 text-slate-200 bg-indigo-500 rounded-xl lg:w-2/12 font-bold" onClick={(e) => handleStepChange(e, 1)}>Zaczynajmy!</button>
            }
            {(currentStep > 0) && <button className="p-3 text-slate-200 bg-indigo-500 rounded-xl lg:w-2/12" onClick={(e) => handleStepChange(e, -1)}>Poprzedni krok</button>}
            {(currentStep > 0 && currentStep < maxStep) && <button className={"p-3 text-slate-200 rounded-xl lg:w-2/12 " + (!canNext ? "bg-indigo-300 disabled hover:cursor-default" : "bg-indigo-500")} onClick={(e) => canNext && handleStepChange(e, 1)}>Następny krok</button>}
            {(currentStep === maxStep) && <input className="animate-bounce hover:animate-none p-3 text-slate-200 bg-indigo-500 rounded-xl lg:w-2/12" type="submit" value="Zatwierdź" />}
          </div>
        </form>


        {(summaryAvailable && currentStep !== maxStep) &&
          <div className="flex flex-col items-center mt-3 text-center">
            <button className="p-3 text-slate-200 bg-indigo-400 rounded-xl w-5/12" onClick={() => { setCurrentStep(maxStep) }}>Wróc do podsumowania</button>
          </div>
        }
      </div>
    </div>
  );
}

export default AddAlert;